import React, { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons/lib/icons";
import { Button, Col, Image, Modal, Row, Steps } from "antd";
import BusinessInformation from "./BusinessInformation/BusinessInformation";
import ContactPersonInfo from "./ContactPersonInfo/ContactPersonInfo";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import StoreInfo from "./StoreInfo/StoreInfo";
import BackIcon from "../../assests/backSVG.svg";
import Styles from "./Supplierreg.module.css";
import { useHistory, useLocation } from "react-router";

const { Step } = Steps;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SupplierRegistrationForm = () => {
  const query = useQuery();
  const stepParam = query.get("step");
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState<number>(() => {
    const paramStep = stepParam ? parseInt(stepParam, 10) : null;
    if (paramStep !== null && !isNaN(paramStep)) {
      return paramStep;
    }

    const storedStep = localStorage.getItem("supplier_form_step");
    const initialStep = storedStep !== null ? parseInt(storedStep, 10) : 0;
    return initialStep !== 4 ? initialStep : 3;
  });

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const goBack = () => {
    history.push("/login");
  };

  const handleExitConfirm = () => {
    setIsModalVisible(false);
    history.push("/login");
  };

  const handleExitCancel = () => {
    setIsModalVisible(false);
  };

  const exitFlow = () => {
    setIsModalVisible(true);
  };

  const stepTitles = [
    "Business Information",
    "Seller Information",
    "Payment Info.",
    "Store Info.",
  ];

  const customIcon = (index: number) => {
    if (index < currentStep) {
      return <CheckOutlined />;
    }
    return (
      <div>
        {String(index + 1)
          .padStart(2, "0")
          .padEnd(3, ".")}
      </div>
    );
  };

  const handleStepChange = (newStep: number) => {
    setCurrentStep(newStep);
    history.push(`${history.location.pathname}?step=${newStep}`);
    localStorage.setItem("supplier_form_step", newStep.toString());

    const rightCol = document.querySelector(".right-col");
    if (rightCol) {
      rightCol.scrollTo({ top: 0, behavior: "smooth" });
      console.log("Scrolled");
    }
  };

  return (
    <Row>
      <Col xs={24} md={8} className="left-col">
        <Button onClick={goBack} className={Styles.backBtn}>
          <Image src={BackIcon} preview={false} />
        </Button>

        <Steps
          current={currentStep}
          direction={isSmallScreen ? "horizontal" : "vertical"}
        >
          {stepTitles.map((title, index) => (
            <Step
              key={index}
              title={isSmallScreen ? "" : title}
              icon={customIcon(index)}
            />
          ))}
        </Steps>
      </Col>
      <Col xs={24} md={16} className="right-col">
        <Button onClick={exitFlow} className={Styles.exitBTN}>
          <p className={Styles.exitText}>Exit</p>
        </Button>
        {currentStep === 0 && <BusinessInformation step={handleStepChange} />}
        {currentStep === 1 && <ContactPersonInfo step={handleStepChange} />}

        {currentStep === 2 && <PaymentInfo step={handleStepChange} />}
        {currentStep === 3 && <StoreInfo step={handleStepChange} />}
      </Col>

      <Modal
        open={isModalVisible}
        onOk={handleExitConfirm}
        onCancel={handleExitCancel}
        centered
        footer={[
          <Button
            key="cancel"
            className={Styles.exitBTNModalStay}
            onClick={handleExitCancel}
          >
            Stay
          </Button>,
          <Button
            key="submit"
            type="primary"
            className={Styles.exitBTNModalExit}
            onClick={handleExitConfirm}
          >
            Exit
          </Button>,
        ]}
      >
        <p className={Styles.exitText}>Are you sure you want to exit?</p>
        Information from this step will be lost, and when you return, you will
        need to continue from here.
      </Modal>
    </Row>
  );
};

export default SupplierRegistrationForm;
