import React, { useState, useEffect } from "react";
import Styles from "./ServiceDetails.module.css";
import {
  Row,
  Col,
  Button,
  Switch,
  notification,
  Drawer,
  Divider,
  Carousel,
  Breadcrumb,
} from "antd";
import { getCapitalize } from "../../../utils/helpers";
import { useHistory, useLocation, useParams } from "react-router";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import CustomButton from "../../../components/Button/Button";
import ServiceCard from "../../../common/Cards/ServiceCard/ServiceCard";
import savedItemImage from "../../../assests/savedItemImage.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";
import servicesList from "../../../data/servicesList";
import EditServiceDrawer from "../../../common/EditServiceDrawer/EditServiceDrawer";
import AddLocationDrawer from "../../../common/AddLocationDrawer/AddLocationDrawer";
import { _GET, _DELETE, getRole, _POST } from "../../../common/commonFunc";
import ImageGallery from "react-image-gallery";
import LeftCircle from "../../../assests/customIcons/LeftCircle";
import RightCircle from "../../../assests/customIcons/RightCircle";
import Delete from "../../../assests/customIcons/Delete";
import Edit from "../../../assests/customIcons/Edit";
import Share from "../../../assests/customIcons/Share";
import Save from "../../../assests/customIcons/Save";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { getRichText } from "../../../utils/dataTransformers";
import RichText from "../../../components/RichText/RichText";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { RWebShare } from "react-web-share";
import _ from "lodash";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import axios from "axios";
import LoginPopup from "../../Login/LoginPopup";
import ImageCarousel from "../../../components/Carousel/Carousel";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import ReviewCard from "../../Events/ServiceDetails/ReviewCard";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RequestQuoteDrawer from "./RequestQuoteDrawer";
import { getFreelancerProfile } from "../../../redux/actions/FreelancerProfile";
import { useDispatch } from "react-redux";
import GlobalCarousel from "../../../components/Global/Carousel/Carousel";
import ServiceProviderCard from "../../Store/ServiceDetails/SupplierCard/SupplierCard";

const ServicesDetails: React.FC = () => {
  const history = useHistory();
  // const userRole = getRole();
  const [userRole, setUserrole] = useState(getRole());
  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });
  const { serviceId } = useParams<{ serviceId: string }>();
  const [service, setService] = useState<any>([]);
  const [serviceData, setServiceData] = useState<any>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>([]);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [reviewsData, setReviewsData] = useState<any>([]);
  const [displayAllCards, setDisplayAllCards] = useState(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openLocDrawer, setOpenLocDrawer] = useState(false);
  const [galleryImages, setGalleryImages] = useState<any>([]);
  const [serviceStatus, setServiceStatus] = useState(true);
  const [addedLocations, setAddedLocations] = useState<any>([]);
  const [preAddedLocations, preSetAddedLocations] = useState<any>([]);
  const [otherServices, setOtherServices] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [allCategories, setAllCategories] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [openRqDrawer, setOpenRqDrawer] = useState(false);
  const [coords, setCoords] = useState<any>(null);
  const [cartItems, setCartItems] = useState<any>([]);
  const [savedItem, setSavedItem] = useState(false);
  const dispatch = useDispatch<any>();
  const [openDrawer1, setopenDrawer1] = useState<any>(false);
  const [freelancerData, setFreelancerData] = useState<any>([]);
  console.log("➡️   ~ freelancerData:", freelancerData);
  const [sameProvider, setSameProvider] = useState<any>(false);
  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );

  const currentPageUrl = window.location.href;

  const toggleDisplayAll = () => {
    setDisplayAllCards(!displayAllCards);
  };
  const visibleReviewsData = displayAllCards
    ? reviewsData
    : reviewsData.slice(0, 1);
  const getReviewsDataHandler = async () => {
    try {
      const response = await _GET(
        `review/users/feedbacks?entity_type=service&version=1&entity_id=${serviceId}`,
        true,
        false
      );
      console.log("response", response);
      setReviewsData(_.get(response, "data", []));
    } catch (error) {
      console.log("🚀 ~ getReviewsDataHandler ~ error:", error);
    }
  };

  useEffect(() => {
    getReviewsDataHandler();
  }, [serviceId]);

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await axios.get(
          ` https://development.theoctopus.ai:8443/services/getServiceDetailsbyId/${serviceId}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("jwtToken"),
            },
          }
        );

        if (response.data && response.data.data) {
          const fetchedService = response.data.data[0];

          setSameProvider(fetchedService.provider_id === userId);
          const isServiceSaved = _.get(fetchedService, "is_saved_item", false);
          setSavedItem(isServiceSaved);
          // console.log("fetchedService", fetchedService.provider_id);

          setService(fetchedService);
          setSelectedLocation(fetchedService?.service_location);
          const location = fetchedService?.service_location;
          preSetAddedLocations([location]);

          setServiceData(fetchedService);
          setServiceStatus(fetchedService?.is_active);
        } else {
          console.error("Service data not found in response");
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
      }
    };

    fetchServiceData();
  }, [serviceId]);

  const userdata = localStorage.getItem("userInfo");
  let userId: any;
  let uname: any;
  if (userdata) {
    const userInfo = JSON.parse(userdata);
    userId = userInfo.id;
    uname = userInfo.first_name;
  }

  const fetchAddressData = async () => {
    if (userRole !== "guest") {
      try {
        const response = await _GET(
          `userProfile/getUserAllAddress/${userId}`,
          true,
          false
        );
        const apiAddresses = response.data[0].user_addresses || [];

        setAddedLocations(apiAddresses);
      } catch (error) {
        console.error("Error while fetching address data", error);
        notification.open({
          type: "error",
          message: "Error while fetching address data",
          description: "Please try again later.",
        });
      }
    }
  };

  useEffect(() => {
    fetchAddressData();
  }, []);

  const openToAdd = () => {
    setOpenDrawer(true);
  };

  const closeToAdd = () => {
    setOpenDrawer(false);
  };

  const openToAddLoc = () => {
    setOpenLocDrawer(true);
  };

  const closeToAddLoc = () => {
    setOpenLocDrawer(false);
  };

  const closeRqDrawer = () => {
    setOpenRqDrawer(false);
  };

  const onChangeStatus = async (checked: any) => {
    setServiceStatus(checked);

    try {
      const response = await _POST(
        `services/updateService/${service.id}`,
        { is_active: checked },
        true
      );

      if (response.data !== null) {
        notification.success({
          message: "Service Status Updated",
          description: `The service is now ${checked ? "active" : "inactive"}.`,
        });
      } else {
        notification.error({
          message: "Update Failed",
          description: "Failed to update the service status.",
        });
      }
    } catch (error) {
      console.error("Failed to update the service status:", error);
      notification.error({
        message: "Error",
        description: "An error occurred while updating the service status.",
      });
    }
  };

  const handleDeleteService = async () => {
    if (isProvider) {
      const response = await _DELETE(
        "services/deleteServiceById/" + service?.id,
        true
      );
      if (response) {
        if (response.message === "Service Deleted Successfully") {
          setUpdateList(true);
          showSuccessCustomModal({
            title: "Service Deleted successfully.",
            smallText: "Your service has been deleted successfully.",
            buttonText: "Continue",
          });
          history.push(APPLICATION_ROUTES.SERVICE);
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to delete your service",
          });
        }
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Login as Service Provider to use this service",
      });
    }
  };

  const confirmDelete = (event: any) => {
    event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure you want to delete ?",
      smallText:
        "If you want to delete this service then you have to cancel all orders of this service",
      onOk: handleDeleteService,
      okText: "Yes",
      cancelText: "No",
    });
  };

  const getOtherServices = async () => {
    if (service.provider_id !== undefined) {
      let response;
      response = await _GET(
        "services/getFreelancerAllServices/" + service.provider_id,
        false
      );

      // const res = await _GET(
      //   `userProfile/freelancerProfile/${service?.provider_id}`,
      //   true,
      //   false
      // );

      if (response) {
        if (response.data) {
          const allServices = response.data;
          const otherServices = allServices.filter(
            (serviceItem: any) => serviceItem.id !== service?.id
          );
          setOtherServices(otherServices);
        } else {
          return notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please reload to get other services by provider.",
          });
        }
      }

      // if (res) {
      //   if (res.data) {
      //     setFreelancerData(res.data);
      //   } else {
      //     return notification.open({
      //       type: "error",
      //       message: "Something Went Wrong",
      //       description: "Please reload to get other services by provider.",
      //     });
      //   }
      // }
    }
  };

  const generateImagesForGallery = () => {
    const serviceImages: any = [];
    service?.service_images?.forEach((imageUrl: string) => {
      serviceImages.push({
        original: imageUrl,
        thumbnail: imageUrl,
      });
    });
    setGalleryImages(serviceImages);
  };

  useEffect(() => {
    generateImagesForGallery();
  }, [service]);

  const getAllCategoriesData = async () => {
    let response;

    response = await _GET("services/getAllCategoriesData", false, false);

    if (response) {
      if (response.data) {
        setCategoriesData(response.data);
        const categories = response.data.map(
          (dataObj: any) => dataObj.service_category_main
        );
        const uniqueCategories: any = [...new Set(categories)];
        uniqueCategories.unshift("All");
        setAllCategories(uniqueCategories);
      }
      // else {
      //   return notification.open({
      //     type: "error",
      //     message: "Something Went Wrong",
      //     description: "Please reload and try again for better experience.",
      //   });
      // }
    }
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
      }
    );
  };

  useEffect(() => {
    if (!isProvider) getOtherServices();
    generateImagesForGallery();
    if (isProvider) getCurrentLocation();
  }, [service && service.provider_id]);

  useEffect(() => {
    generateImagesForGallery();
  }, [service]);

  useEffect(() => {
    setService(serviceData);
  }, [serviceData]);

  const id = serviceData.id;

  const handleServiceClick = (service: any) => {
    history.push(`${APPLICATION_ROUTES.SERVICE_BOOKING}/${serviceData.id}`, {
      serviceData: serviceData,
    });
  };

  const handleAddToCart = async () => {
    const payload = {
      itemDetails: {
        itemId: serviceData.id,
        itemCategory: serviceData.service_category_main,
        itemPrice: serviceData.service_fees,
        itemName: serviceData.service_name,
        itemPic: serviceData.service_images[0],
        quantity: 1,
        itemType: "service",
      },
    };
    try {
      const response = await _POST(`/cart/addItem/${userId}`, payload, true);
      if (response && response.status === false) {
        notification.open({
          type: "error",
          message: `${response.message}`,
          description: _.get(response, "response.message", ""),
        });
      } else {
        setCartItems((prevItems: any) => [...prevItems, payload.itemDetails]);
        notification.success({
          message: "Added to Cart Successful",
          description: "You have successfully Added to Cart.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCartItem = async () => {
    if (userRole !== "guest") {
      try {
        const res = await _GET(`/cart/getItem/${userId}`, true);

        if (res.status) {
          const itemsArray: any[] = Object.values(res.data)
            .map((item: any) => JSON.parse(item))
            .flat();

          const serviceItems: any = itemsArray.filter(
            (item) => item.itemType === "service"
          );
          setCartItems(serviceItems);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchCartItem();
  }, []);

  const isItemInCart = (serviceId: any) => {
    return cartItems.some((item: { itemId: any }) => item.itemId === serviceId);
  };

  const checkProvider = () => {
    serviceData.provider_id === userId
      ? setSameProvider(true)
      : setSameProvider(false);
  };

  const handleSavedItems = async () => {
    const payload = {
      itemType: "service",
      itemId: serviceId,
    };

    try {
      const postData = await _POST("userProfile/saveItem", payload, true);
      setSavedItem(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const formatAddress = (locationJson: any) => {
    const location = JSON.parse(locationJson);
    let addressParts = [];

    if (location.address_name) addressParts.push(location.address_name);
    if (location.building_no) addressParts.push(location.building_no);
    if (location.floor) addressParts.push(`Floor ${location.floor}`);
    if (location.address) addressParts.push(location.address);
    if (location.city) addressParts.push(location.city);
    if (location.state) addressParts.push(location.state);
    if (location.country) addressParts.push(location.country);
    if (location.landmark) addressParts.push(`Landmark: ${location.landmark}`);
    if (location.pinCode) addressParts.push(`PIN: ${location.pinCode}`);

    return addressParts.join(", ");
  };

  const allowedTags = ["b", "i", "em", "strong", "a", "img", "p", "div", "br"];
  const allowedAttributes: any = {
    a: ["href", "name", "target"],
    img: ["src", "alt", "title"],
    "*": ["style", "class"],
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const sanitizeAndEnsureFirstElement = (html: any) => {
    const div = document.createElement("div");
    div.innerHTML = html;

    const sanitizeNode = (node: any) => {
      if (node.nodeType === 1) {
        if (!allowedTags.includes(node.tagName.toLowerCase())) {
          node.parentNode.removeChild(node);
          return;
        }

        const attributes = Array.from(node.attributes);
        attributes.forEach((attr: any) => {
          const attrName = attr.name.toLowerCase();
          const tagName = node.tagName.toLowerCase();
          if (
            !(
              allowedAttributes["*"]?.includes(attrName) ||
              allowedAttributes[tagName]?.includes(attrName)
            )
          ) {
            node.removeAttribute(attrName);
          } else if (attrName.startsWith("on")) {
            node.removeAttribute(attrName);
          } else if (tagName === "img" && attrName === "src") {
            if (!isValidUrl(node.getAttribute(attrName))) {
              node.removeAttribute(attrName);
            }
          }
        });

        Array.from(node.childNodes).forEach(sanitizeNode);
      } else if (node.nodeType === 3) {
        const tempDiv = document.createElement("div");
        tempDiv.textContent = node.textContent;
        node.parentNode.replaceChild(tempDiv, node);
      }
    };

    Array.from(div.childNodes).forEach(sanitizeNode);

    const firstElement = div.firstChild;
    if (firstElement && firstElement.nodeType === 1) {
      return div.innerHTML;
    } else {
      return "";
    }
  };

  const truncate = (str: any, n: any) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const stripHtml = (html: any) => {
    const temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  const serviceDesc = _.get(service, "service_desc", "");

  const sanitizedDesc = sanitizeAndEnsureFirstElement(serviceDesc);
  console.log("sanitizedDesc", sanitizedDesc);
  const strippedDesc = stripHtml(sanitizedDesc);
  const truncatedDesc = truncate(strippedDesc, 100);

  const shareText = `Checkout New Service:\n Service Name: ${
    service?.service_name
  }\n ${strippedDesc.slice(0, 100)}\n Link:\n`;

  // here
  const onFreelancerProfileClickHandler = async () => {
    await dispatch(
      getFreelancerProfile(_.get(service, "provider_id", ""), (value: any) => {
        setopenDrawer1(value);
      })
    );
  };

  const ServiceProviderDetails = () => {
    const renderProfilePicOrInitial = () => {
      if (
        service?.service_provider_details?.profile_pic &&
        service?.service_provider_details?.profile_pic !== "null" &&
        service?.service_provider_details?.profile_pic !== "NULL" &&
        service?.service_provider_details?.profile_pic !== null &&
        service?.service_provider_details?.profile_pic !== undefined &&
        service?.service_provider_details?.profile_pic !== ""
      ) {
        return (
          <img
            src={service?.service_provider_details?.profile_pic}
            alt={
              service?.service_provider_details?.display_name ||
              service?.service_provider_details?.first_name ||
              "User"
            }
            className={Styles.profilePic}
          />
        );
      } else {
        const initial = (
          service?.service_provider_details?.display_name ||
          service?.service_provider_details?.first_name ||
          ""
        )
          .charAt(0)
          .toUpperCase();
        return <div className={Styles.initialsCircle}>{initial}</div>;
      }
    };

    return (
      <div
        className={Styles.serviceProviderDetails}
        onClick={onFreelancerProfileClickHandler}
      >
        <div
          className={Styles.profilePicWrapper}
          onClick={onFreelancerProfileClickHandler}
        >
          {renderProfilePicOrInitial()}
        </div>
        <div className={Styles.serviceProviderInfo}>
          <h2>
            {service?.service_provider_details?.display_name ||
              service?.service_provider_details?.first_name ||
              "Unknown"}
          </h2>
          {/* <p className={Styles.bio}>
            {stripHtml(
              freelancerData?.user_bio
                ? freelancerData?.user_bio.replace(/{.*?}/g, "")
                : "No bio available"
            )}
          </p>
          <p className={Styles.followers}>
            Followers: {freelancerData?.octopus_follower_count || 0}
          </p>
          <p className={Styles.collaborationStatus}>
            Open for Collaboration:{" "}
            {freelancerData?.open_for_collaboration ? "Yes" : "No"}
          </p> */}
        </div>
      </div>
    );
  };

  const pageTitle = service?.service_name || "Service Details";
  const pageDescription = service?.service_desc || "Service Details";

  return (
    <HelmetProvider>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LoginPopup open={isLoginPopupOpen} onClose={handleCloseLoginPopup} />
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <EditServiceDrawer
          service={service}
          setService={setService}
          openDrawer={openDrawer}
          closeToAdd={closeToAdd}
          openToAddLoc={openToAddLoc}
          setUpdateList={setUpdateList}
          categoriesData={categoriesData}
          addedLocations={addedLocations}
          getAllCategoriesData={getAllCategoriesData}
          coords={coords}
          preset={preAddedLocations}
        />
        <AddLocationDrawer
          openLocDrawer={openLocDrawer}
          closeToAddLoc={closeToAddLoc}
          setAddedLocations={setAddedLocations}
          coords={coords}
          setCoords={setCoords}
        />
        <div className={Styles.serviceMainContainer}>
          <Breadcrumb
            style={{ margin: "16px 0" }}
            separator={<img src={breadcrumbIcon} alt=">" />}
            className={Styles.breadcrumbContainer}
          >
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() => handleBreadcrumbClick("/service")}
            >
              Services
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() => handleBreadcrumbClick("/service/" + serviceId)}
            >
              Service Details
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row gutter={36}>
            <Col lg={12}>
              {/* {serviceData !== undefined && (
                <ImageCarousel images={serviceData?.service_images} />
              )} */}
              {/* 
              <ImageGallery
                items={galleryImages}
                showFullscreenButton={false}
                showPlayButton={false}
                renderLeftNav={(onClick, disabled) => (
                  <Button
                    onClick={onClick}
                    disabled={disabled}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "absolute",
                      top: "90%",
                      left: "4%",
                      width: "40px",
                      height: "40px",
                      padding: 0,
                      background: "rgba(255,255,255, 0.5)",
                    }}
                  >
                    <LeftCircle />
                  </Button>
                )}
                renderRightNav={(onClick, disabled) => (
                  <Button
                    onClick={onClick}
                    disabled={disabled}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "absolute",
                      top: "90%",
                      right: "4%",
                      width: "40px",
                      height: "40px",
                      padding: 0,
                      background: "rgba(255,255,255, 0.5)",
                    }}
                  >
                    <RightCircle />
                  </Button>
                )}
              /> */}
              <GlobalCarousel images={galleryImages} />
              {!isProvider && service && (
                <div className={Styles.boxInfoContainer}>
                  <div className={Styles.collabration}>
                    <p>
                      <span className={Styles.collabBrand}>
                        Service Provider
                      </span>
                    </p>
                  </div>
                  <ServiceProviderCard
                    serviceProviderDetails={service?.service_provider_details}
                    providerID={service?.provider_id}
                  />
                </div>
              )}
            </Col>
            <Col xs={18} md={20} lg={10}>
              <div className={Styles.serviceInfo}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  {service?.service_tot_fee && (
                    <p style={{ fontSize: "18px", color: "#666" }}>
                      BHD{" "}
                      <b style={{ color: "#30263E" }}>
                        {service?.service_tot_fee}
                      </b>
                    </p>
                  )}
                  {service?.service_category_main && (
                    <Button
                      className="elevatedElement"
                      style={{ borderRadius: "25px" }}
                    >
                      <b>{service?.service_category_main}</b>
                    </Button>
                  )}
                </div>
                {service?.service_name && (
                  <h2>{getCapitalize(service?.service_name)}</h2>
                )}
                {sanitizedDesc && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: showFullDescription
                          ? sanitizedDesc
                          : `${sanitizedDesc.slice(0, 100)}...`,
                      }}
                      className={`richDesc ${Styles.serviceDescription}`}
                      style={{
                        width: "100%",
                      }}
                    ></div>
                    {!showFullDescription && (
                      <p
                        className="readMore"
                        onClick={() => setShowFullDescription(true)}
                      >
                        Continue Reading
                      </p>
                    )}
                  </div>
                )}
                <Drawer
                  title="Full Description"
                  placement="right"
                  closable={true}
                  onClose={() => setShowFullDescription(false)}
                  open={showFullDescription}
                  width={400}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: sanitizedDesc }}
                    className={Styles.serviceDescription}
                  ></div>
                </Drawer>
                {!isProvider && !service?.allowed_quotes && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div className={Styles.buttonContainer}>
                      {isItemInCart(serviceData.id) ? (
                        <Button
                          type="primary"
                          className={Styles.addedToCart}
                          disabled
                        >
                          Already Added to Cart
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className={Styles.addToCartButton}
                          onClick={
                            userRole !== "guest"
                              ? handleAddToCart
                              : handleOpenLoginPopup
                          }
                        >
                          Add to Cart
                        </Button>
                      )}
                      <Button
                        className={Styles.bookNowButton}
                        onClick={
                          userRole !== "guest"
                            ? handleServiceClick
                            : handleOpenLoginPopup
                        }
                      >
                        Book Now
                      </Button>
                    </div>
                  </div>
                )}
                {!isProvider && service?.allowed_quotes && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      marginTop: "20px",
                    }}
                  >
                    {service?.is_service_quoted ? (
                      <Button
                        type="primary"
                        className={Styles.quoteButton}
                        disabled
                      >
                        Quote already requested
                      </Button>
                    ) : (
                      <>
                        <Button
                          type="primary"
                          className={Styles.quoteButton}
                          // onClick={() => setOpenRqDrawer(true)}
                          onClick={() => {
                            userRole !== "guest"
                              ? setOpenRqDrawer(true)
                              : handleOpenLoginPopup();
                          }}
                        >
                          Request a quote
                        </Button>
                        <LightTooltip
                          title={
                            <>
                              Request for a customised service quote from
                              freelancer
                            </>
                          }
                          placement="bottom"
                          style={{ marginTop: "5px" }}
                        >
                          <div className={Styles.infoIcon}>
                            {" "}
                            <InfoOutlinedIcon sx={{ fontSize: "large" }} />
                          </div>
                        </LightTooltip>
                      </>
                    )}
                  </div>
                )}
                <div className={Styles.boxInfoContainer}>
                  {service?.service_duration && (
                    <div className={`${Styles.boxInfo} ${Styles.borderBox}`}>
                      <p>Duration</p>
                      <h3>{service?.service_duration}</h3>
                    </div>
                  )}
                  {/* {isProvider && (
                  <div className={`${Styles.boxInfo} ${Styles.borderBox}`}>
                    <p>Bookings</p>
                    <h3>80</h3>
                  </div>
                )} */}
                  {/* {isProvider && (
                    <div className={`${Styles.boxInfo} ${Styles.borderBox}`}>
                      <p>Home Fee</p>
                      <p style={{ fontSize: "16px", color: "#666" }}>
                        BHD <b style={{ color: "#30263E" }}>10.00</b>
                      </p>
                    </div>
                  )} */}
                  {isProvider && sameProvider && (
                    <div className={`${Styles.boxInfo} ${Styles.switchBox}`}>
                      <p>Service Status</p>
                      <Switch
                        onChange={onChangeStatus}
                        checked={serviceStatus}
                      />
                    </div>
                  )}
                  {!isProvider && (
                    <div className={`${Styles.boxInfo}`}>
                      <p>Payment</p>
                      <p style={{ fontSize: "16px", color: "#666" }}>
                        <b style={{ color: "#30263E" }}>
                          {`${service?.service_pay_model}% Advance`}
                        </b>
                      </p>
                    </div>
                  )}
                </div>

                {isProvider && sameProvider && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "separate",
                        borderSpacing: "0 10px",
                        padding: "10px",
                        marginTop: "1rem",
                        borderRadius: "15px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "3rem",
                            }}
                          >
                            Provider Fee
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "30px",
                            }}
                          >
                            -
                          </td>

                          <td
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#30263E",
                            }}
                          >
                            {`BHD ${service?.service_fees}`}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "3rem",
                            }}
                          >
                            Location Fee
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "30px",
                            }}
                          >
                            -
                          </td>

                          <td
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#30263E",
                            }}
                          >
                            {`BHD ${
                              service?.service_loc_fee !== null
                                ? service?.service_loc_fee
                                : 0
                            }`}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "10px",
                            }}
                          >
                            Platform fee{" "}
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "30px",
                            }}
                          >
                            -
                          </td>

                          <td
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#30263E",
                            }}
                          >
                            {` (-) BHD ${service?.service_platform_fee}`}
                          </td>
                        </tr>
                        {/* <tr>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "3rem",
                            }}
                          >
                            VAT on Service{" "}
                            {`(${service?.service_vat_percentage}%)`}{" "}
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "30px",
                            }}
                          >
                            -
                          </td>

                          <td
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#30263E",
                            }}
                          >
                            {`BHD ${service?.service_vat_amount}`}{" "}
                          </td>
                        </tr> */}
                        <Divider />
                        <tr>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "3rem",
                            }}
                          >
                            Advance Pay
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "30px",
                            }}
                          >
                            -
                          </td>

                          <td
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#30263E",
                            }}
                          >
                            {`${service?.service_pay_model}%`}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "20px", color: "#666" }}>
                            Balance Payment{" "}
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#666",
                              paddingRight: "30px",
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#30263E",
                            }}
                          >
                            {`${100 - service?.service_pay_model}%`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {service?.service_location_new !== null ? (
                  <div
                    className={Styles.flexCol}
                    style={{
                      backgroundColor: "white",
                      width: "95%",
                      padding: "1rem",
                      borderRadius: "15px",
                    }}
                  >
                    <p>
                      {isProvider && sameProvider
                        ? "My Address"
                        : "Service Location"}
                    </p>
                    <p style={{ fontSize: "18px", color: "#666" }}>
                      <b style={{ color: "#30263E" }}>
                        {service?.service_location_new?.address}
                      </b>
                    </p>
                  </div>
                ) : (
                  <div
                    className={Styles.flexCol}
                    style={{
                      backgroundColor: "white",
                      width: "95%",
                      padding: "1rem",
                      borderRadius: "15px",
                    }}
                  >
                    <p>
                      {isProvider && sameProvider
                        ? "My Address"
                        : "Service Location"}
                    </p>
                    <p style={{ fontSize: "18px", color: "#666" }}>
                      <b style={{ color: "#30263E" }}>
                        {formatAddress(service?.service_location)}
                      </b>
                    </p>
                  </div>
                )}

                {service?.service_at_customer_location === "yes" && (
                  <div className={Styles.customProvider}>
                    <div className={Styles.customProviderText}>
                      <p style={{ fontWeight: "600" }}>
                        I Provide this service at customer location
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {!isProvider && otherServices.length > 0 && (
                <div className={Styles.othersOfProvider}>
                  <h2>{`${
                    service?.service_provider_details.display_name
                      ? service?.service_provider_details.display_name
                      : service?.service_provider_details.first_name
                  }'s Services`}</h2>
                  <div className={Styles.otherServices}>
                    {otherServices.map((service: any) => (
                      <ServiceCard
                        key={service?.id}
                        service={service}
                        setUpdateList={setUpdateList}
                        similarBy={true}
                        categoriesData={categoriesData}
                      />
                    ))}
                  </div>
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  justifyItems: "center",
                }}
              >
                <br />
                <br />
                <br />
                <br />
                <div
                  style={{
                    display: "flex",

                    height: "100%",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>{reviewsData.length} Reviews</h2>
                </div>
                <div
                  onClick={toggleDisplayAll}
                  style={{
                    display: "flex",

                    height: "100%",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "end",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  See All
                </div>
              </div>
              <ReviewCard reviews={visibleReviewsData} />
            </Col>
            <Col span={2}>
              <div className={Styles.serviceActions}>
                {isProvider && sameProvider && (
                  <button
                    onClick={confirmDelete}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Delete />
                  </button>
                )}
                {isProvider && sameProvider && (
                  <button
                    onClick={openToAdd}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Edit />
                  </button>
                )}
                {!isProvider && !savedItem ? (
                  <button
                    onClick={(event: any) => {
                      handleSavedItems();
                      event.preventDefault();
                    }}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Save />
                  </button>
                ) : (
                  !isProvider && (
                    <img
                      style={{
                        position: "relative",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                      src={savedItemImage}
                    ></img>
                  )
                )}
                <div>
                  <RWebShare
                    data={{
                      text: shareText,
                      url: currentPageUrl,
                      title: service?.service_name,
                    }}
                  >
                    <button
                      className="elevatedElement iconBtn"
                      style={{
                        position: "relative",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                    >
                      <Share />
                    </button>
                  </RWebShare>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <RequestQuoteDrawer
        isVisible={openRqDrawer}
        id={serviceId}
        name={service?.service_name}
        onClose={closeRqDrawer}
      />
    </HelmetProvider>
  );
};

export default ServicesDetails;
