import React from "react";

const InstagramfetchFollowers = () => {
  function setupInsta() {
    let appId = "241619375493857";
    let redUri = "https://development.theoctopus.ai:8443/insta";
    let url = `https://api.instagram.com/oauth/authorize?client_id=${appId}&redirect_uri=${redUri}&scope=user_profile,user_media&response_type=code`;
    // @ts-ignore
    window.open(url, "_blank").focus();
  }
  setupInsta();
  return <div>Instagram</div>;
};

export default InstagramfetchFollowers;
