import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { Spin, notification, Button } from "antd";
import Lottie from "react-lottie-player";
import lottieSuccess from "../../assets/animations/success.json";
import lottieFailure from "../../assets/animations/failure.json";
import Styles from "./KYCVerificationResult.module.css";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";

const KYCVerificationResult: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const linkId = searchParams.get("link");

    if (linkId) {
      fetchKYCRecordByLink(linkId);
    } else {
      notification.error({
        message: "Error",
        description: "No verification link provided.",
      });
      setIsLoading(false);
    }
  }, [location.search]);

  const fetchKYCRecordByLink = async (linkId: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://development.theoctopus.ai:8443/ekyc/record/${linkId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.ok && data.referenceId) {
        setIsSuccess(data.isValid);
        if (data.isValid) {
          notification.success({
            message: "Verification Successful",
            description: "Your KYC verification was successful!",
          });
          localStorage.setItem("ekyc_response_id", data.referenceId);
        } else {
          notification.error({
            message: "Verification Failed",
            description: "Your KYC verification was not successful.",
          });
        }
      } else {
        notification.error({
          message: "Error",
          description: data.error || "Failed to retrieve KYC record.",
        });
        setIsSuccess(false);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to retrieve KYC record. Please try again.",
      });
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onRetryHandler = () => {
    history.push(APPLICATION_ROUTES.E_KYC);
  };

  const onContinueHandler = () => {
    history.push(APPLICATION_ROUTES.REGISTER);
  };

  return (
    <div className={Styles.verificationContainer}>
      <video autoPlay loop muted className={Styles.backgroundVideo}>
        <source
          src="https://octopus-platform-assets.s3.me-south-1.amazonaws.com/web_homepage/Octopus_homepage_animation.mp4"
          type="video/mp4"
        />
      </video>
      {isLoading ? (
        <div className={Styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div className={Styles.messageContainer}>
          {isSuccess !== null && (
            <>
              <Lottie
                loop={true}
                animationData={isSuccess ? lottieSuccess : lottieFailure}
                play
                style={{ width: 200, height: 200 }}
              />
              <div
                className={
                  isSuccess ? Styles.successMessage : Styles.errorMessage
                }
              >
                {isSuccess
                  ? "Your KYC verification was successful!"
                  : "Your KYC verification was not successful."}
              </div>
              {!isSuccess && (
                <div className={Styles.retryContainer}>
                  <p>
                    You can manually upload your documents during registration
                    or try the verification process again.
                  </p>
                  <div className={Styles.buttonsContainer}>
                    <Button
                      type="primary"
                      onClick={onRetryHandler}
                      className={Styles.retryBtn}
                    >
                      Retry KYC Verification
                    </Button>
                    <Button
                      type="default"
                      onClick={onContinueHandler}
                      className={Styles.continueBtn}
                    >
                      Continue to Registration
                    </Button>
                  </div>
                </div>
              )}
              {isSuccess && (
                <div className={Styles.buttonsContainer}>
                  <Button
                    type="primary"
                    onClick={onContinueHandler}
                    className={Styles.continueBtn}
                  >
                    Continue to Registration
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default KYCVerificationResult;
