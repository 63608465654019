import {
  EditOutlined,
  SaveOutlined,
  BankOutlined,
  HomeOutlined,
  FieldNumberOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Card,
  Form,
  Input,
  Select,
  DatePicker,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import FileUpload from "../../../../../components/InputComponents/FileUpload";
import countries from "../../../Data/countries.json";
import styles from "./EditSupplier.module.css";
import moment from "moment";
import { _PATCH, _POST } from "../../../../../common/commonFunc";

const { Option } = Select;

const BusinessInfoCard = ({ userInfo, onSave, back }: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [form] = Form.useForm();
  const [documentImages, setDocumentImages] = useState<any>({});
  const [existingPreviews, setExistingPreviews] = useState<any>([]);

  useEffect(() => {
    if (userInfo) {
      const initialFormValues = {
        country: userInfo?.business_info?.country,
        city: userInfo?.business_info?.city,
        businessLocation: userInfo?.business_info?.businessLocation,
        commercialRegistrationNumber:
          userInfo?.business_info?.commercialRegistrationNumber,
        businessName: userInfo?.business_info?.businessName,
        businessType: userInfo?.business_info?.businessType,
        expiryDate: userInfo.business_info?.expiryDate
          ? moment(userInfo.business_info.expiryDate).isBefore(
              moment().startOf("day")
            )
            ? null
            : dayjs(userInfo.business_info.expiryDate)
          : null,
        ownershipStatus: userInfo?.business_info?.ownershipStatus,
        businessAddressLine1: userInfo?.business_info?.businessAddressLine1,
        businessAddressLine2: userInfo?.business_info?.businessAddressLine2,
        commercialDocument: userInfo?.business_info?.commercialDocumentUrl
          ? [
              {
                uid: "-1",
                name: userInfo?.business_info?.commercialDocumentName,
                status: "done",
                url: userInfo?.business_info?.commercialDocumentUrl,
              },
            ]
          : [],
      };
      form.setFieldsValue(initialFormValues);
      setInitialValues(initialFormValues);
      setExistingPreviews(
        initialFormValues.commercialDocument.map((doc: any) => doc.url)
      );
    }
  }, [userInfo, form]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    form.setFieldsValue(initialValues);
    setIsEditing(false);
  };

  const uploadImages = async () => {
    if (documentImages.commercialDocument) {
      const formData = new FormData();
      formData.append("images", documentImages.commercialDocument);

      try {
        const response: any = await _POST(
          `uploadImagesToS3/supplier_document`,
          formData,
          true
        );

        if (response?.data?.length > 0) {
          const uploadedImages = response.data;
          return uploadedImages[0];
        }
      } catch (error) {
        console.error("Error uploading images:", error);
        message.error("Failed to upload image.");
      }
    }
    return null;
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      if (existingPreviews.length === 0 && !documentImages.commercialDocument) {
        message.error("Commercial document is required");
        return;
      }

      const uploadedDocumentUrl = await uploadImages();

      const businessInfo = {
        country: values.country,
        city: values.city,
        expiryDate: values.expiryDate
          ? values.expiryDate.format("YYYY-MM-DD")
          : "",
        businessName: values.businessName,
        businessType: values.businessType,
        ownershipStatus: values.ownershipStatus,
        businessLocation: values.businessLocation,
        businessAddressLine1: values.businessAddressLine1,
        businessAddressLine2: values.businessAddressLine2,
        commercialDocumentUrl: uploadedDocumentUrl
          ? uploadedDocumentUrl
          : initialValues.commercialDocument[0]?.url,
        commercialDocumentName: uploadedDocumentUrl
          ? documentImages.commercialDocument.name
          : initialValues.commercialDocument[0]?.name,
        commercialRegistrationNumber: values.commercialRegistrationNumber,
      };

      const response = await _PATCH(
        `userProfile/supplier/details`,
        { business_info: businessInfo },
        true
      );

      if (response?.status === true && response?.statusCode === 200) {
        message.success("Business Info updated successfully");
        setIsEditing(false);
        onSave();
      } else {
        message.error("Failed to update Business Info");
      }
    } catch (error) {
      console.error("Error updating Business Info:", error);
      message.error("An error occurred while updating Business Info");
    }
  };

  return (
    <Card
      title={
        userInfo.business_info ? (
          "Business Info and Ownership"
        ) : (
          <p style={{ color: "#ff4d4f" }}>
            No Business Information. You can edit and add here.
          </p>
        )
      }
      extra={
        <div style={{ display: "flex", gap: "10px" }}>
          {isEditing && (
            <Button
              icon={<CloseOutlined />}
              onClick={handleCancel}
              className={styles.saveBtn}
            >
              Cancel
            </Button>
          )}
          <Button
            type="primary"
            icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
            onClick={isEditing ? handleSave : handleEditClick}
            className={`${styles.saveBtn} ${
              isEditing ? styles.saveSmallBtn : ""
            }`}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
        </div>
      }
      className={styles.businessCard}
    >
      <Form
        form={form}
        style={{ marginTop: "0 !important" }}
        layout="vertical"
        initialValues={initialValues}
        className={styles.form}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="businessLocation"
              label="Business Location"
              rules={[
                {
                  required: true,
                  message: "Please select a business location",
                },
              ]}
            >
              <Select
                disabled={!isEditing}
                placeholder="Select country"
                showSearch
                optionFilterProp="children"
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
              >
                {countries
                  .filter((country) => country.code === "bh")
                  .map((country) => (
                    <Option key={country.code} value={country.name}>
                      <img
                        src={country.flag}
                        alt={country.name}
                        style={{ marginRight: 8, width: 20 }}
                      />
                      {country.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="businessName"
              label="Business Name"
              rules={[
                { required: true, message: "Please enter business name" },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                //={<HomeOutlined />}
                placeholder="Enter business name"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="commercialRegistrationNumber"
              label="Commercial Registration Number"
              rules={[
                {
                  required: true,
                  message: "Please enter registration number",
                },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                //={<FieldNumberOutlined />}
                placeholder="Enter registration number"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="businessType"
              label="Business Type"
              rules={[
                { required: true, message: "Please select business type" },
              ]}
            >
              <Select
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                placeholder="Select business type"
                style={{ color: "#30263e !important" }}
                onChange={(value) => {
                  form.setFieldValue("businessType", value);
                  if (value === "home_based_business") {
                    localStorage.setItem("businessType", "home_based_business");
                  } else {
                    localStorage.removeItem("businessType");
                  }
                }}
              >
                <Option value="retail">Retail</Option>
                <Option value="ecommerce">E-commerce</Option>
                <Option value="services">Services</Option>
                <Option value="manufacturing">Manufacturing</Option>
                <Option value="home_based_business">Home-Based Business</Option>
                <Option value="non_profit">Non-Profit Organization</Option>
                <Option value="consulting">Consulting</Option>
                <Option value="freelancing">Freelancing</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="expiryDate"
              label="Expiry Date"
              rules={[{ required: true, message: "Please select expiry date" }]}
            >
              <DatePicker
                disabled={!isEditing}
                style={{ width: "100%" }}
                placeholder="Select expiry date"
                className={styles.dateInput}
                disabledDate={(current) =>
                  current && current < moment().startOf("day")
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="ownershipStatus"
              label="Ownership Status"
              rules={[
                {
                  required: true,
                  message: "Please enter ownership status",
                },
              ]}
            >
              <Select
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                placeholder="Select ownership status"
              >
                <Option value="sole_proprietorship">Sole Proprietorship</Option>
                <Option value="general_partnership">General Partnership</Option>
                <Option value="limited_partnership">Limited Partnership</Option>
                <Option value="wll">Limited Liability Company (WLL)</Option>
                <Option value="public_shareholding">
                  Public Shareholding Company
                </Option>
                <Option value="closed_shareholding">
                  Closed Shareholding Company
                </Option>
                <Option value="single_person">Single Person Company</Option>
                <Option value="foreign_branch">
                  Branch of a Foreign Company
                </Option>
                <Option value="representative_office">
                  Representative Office
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: "Please enter the city",
                },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                //={<BankOutlined />}
                placeholder="Enter business city"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="businessAddressLine1"
              label="Business Address Line 1"
              rules={[
                { required: true, message: "Please enter address line 1" },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                //={<HomeOutlined />}
                placeholder="Enter address line 1"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="businessAddressLine2"
              label="Business Address Line 2"
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                //={<HomeOutlined />}
                placeholder="Enter address line 2"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Please enter the country",
                },
              ]}
            >
              <Select
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                placeholder="Select country"
                showSearch
                optionFilterProp="children"
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.name}>
                    <img
                      src={country.flag}
                      alt={country.name}
                      style={{ marginRight: 8, width: 20 }}
                    />
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} className={styles.uploadContainer}>
            <Form.Item
              name="commercialDocument"
              label={
                <p>
                  <span className="error">*</span> Commercial Document
                </p>
              }
            >
              <FileUpload
                title1="Upload Image"
                description="PDF, JPG, PNG file with max size of 10mb."
                setData={setDocumentImages}
                name="commercialDocument"
                existingPreviews={existingPreviews}
                setExistingPreviews={setExistingPreviews}
                disabled={!isEditing}
                alllowAll
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BusinessInfoCard;
