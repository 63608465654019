import React, { useState, useEffect } from "react";
import Styles from "./productDetails.module.css";
import {
  Row,
  Col,
  Button,
  Switch,
  notification,
  Drawer,
  Divider,
  Form,
  Select,
  Tag,
} from "antd";
import { getCapitalize } from "../../../utils/helpers";
import { useHistory, useLocation, useParams } from "react-router";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import CustomButton from "../../../components/Button/Button";
import ServiceCard from "../../../common/Cards/ServiceCard/ServiceCard";
import savedItemImage from "../../../assests/savedItemImage.png";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";
import servicesList from "../../../data/servicesList";
import EditServiceDrawer from "../../../common/EditServiceDrawer/EditServiceDrawer";
import AddLocationDrawer from "../../../common/AddLocationDrawer/AddLocationDrawer";
import { _GET, _DELETE, getRole, _POST } from "../../../common/commonFunc";
import ImageGallery from "react-image-gallery";
import LeftCircle from "../../../assests/customIcons/LeftCircle";
import RightCircle from "../../../assests/customIcons/RightCircle";
import Delete from "../../../assests/customIcons/Delete";
import Edit from "../../../assests/customIcons/Edit";
import Share from "../../../assests/customIcons/Share";
import Save from "../../../assests/customIcons/Save";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import "react-day-picker/dist/style.css";
import { RWebShare } from "react-web-share";
import _ from "lodash";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import axios from "axios";
import LoginPopup from "../../Login/LoginPopup";
import PlusIcon from "../../../assests/PlusIcon.svg";
import MinusIcon from "../../../assests/MinusIcon.svg";
import ServiceProviderCard from "../ServiceDetails/SupplierCard/SupplierCard";
import ReviewCard from "../../Events/ServiceDetails/ReviewCard";
import { useDispatch } from "react-redux";
import { SetProductOrderingProduct } from "../../../redux/actions/ProductOrderAction";
import LoginPopUp from "../../Login/LoginPopup";
import GlobalCarousel from "../../../components/Global/Carousel/Carousel";
// import ServiceProviderCard from "./SupplierCard/SupplierCard";

const { Option } = Select;

const ProductDetails: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const userRole = getRole();
  const [userRole, setUserrole] = useState(getRole());
  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });
  const [form] = Form.useForm();
  const { serviceId } = useParams<{ serviceId: string }>();
  const [service, setService] = useState<any>([]);

  const [serviceData, setServiceData] = useState<any>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>([]);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [size, setSize] = useState<any>("");
  const [itemColor, setItemColor] = useState<any>("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [preAddedLocations, preSetAddedLocations] = useState<any>([]);
  const [otherServices, setOtherServices] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [coords, setCoords] = useState<any>(null);
  const [cartItems, setCartItems] = useState<any>([]);
  const [savedItem, setSavedItem] = useState(false);
  const [reviewsData, setReviewsData] = useState<any>([]);
  const [displayAllCards, setDisplayAllCards] = useState(false);
  const [count, setCount] = useState(1);
  const [selectedTags, setSelectedTags] = React.useState<any>(null);
  const handleChange = (index: string) => {
    setSelectedTags(index);
    setCount(1);
  };

  const location = useLocation<any>();
  const [selectedColor, setSelectedColor] = useState<any>(false);
  const [selectedSize, setSelectedSize] = useState<any>(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);
  const isBuyNowDisabled = !selectedColor || !selectedSize;
  const isAddCartDisabled = !selectedColor || !selectedSize;
  const getReviewsDataHandler = async () => {
    try {
      const response = await _GET(
        `review/users/feedbacks?entity_type=product&version=1&entity_id=${serviceId}`,
        true,
        false
      );
      console.log("response", response);
      setReviewsData(_.get(response, "data", []));
    } catch (error) {
      console.log("🚀 ~ getReviewsDataHandler ~ error:", error);
    }
  };

  useEffect(() => {
    getReviewsDataHandler();
  }, [serviceId]);

  // useEffect(() => {
  //   setCount(1);
  // }, [size, itemColor]);

  const decrementCount = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const incrementCount = () => {
    var CurrentInventory = serviceData.inventory.filter(
      (item: any) => item.size === size && item.color === itemColor
    );
    console.log("INVENTORY IAM GETTING IS", CurrentInventory);

    if (
      count <= CurrentInventory[0]?.quantity &&
      CurrentInventory[0]?.quantity > 0
    ) {
      setCount((prevCount) => prevCount + 1);
    } else {
      notification.warning({
        message: "Stock Limit Reached",
        description: "You cannot add more items than available stock.",
      });
    }
  };

  const currentPageUrl = window.location.href;
  const { productData, categoriesInfo, supplierId } = location.state;

  const fetchServiceData = async () => {
    try {
      const response = await axios.get(
        ` https://development.theoctopus.ai:8443/supplier/assignedProducts/${serviceId}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );

      if (response.data && response.data.data) {
        const fetchedService = response.data.data[0];
        console.log("SERVICE DATA IS", fetchedService);
        setCategoriesData(response.data.data[0].prod_category);
        setService(fetchedService);
        setServiceData(fetchedService);
      } else {
        console.error("Service data not found in response");
      }
    } catch (error) {
      console.error("Error fetching service data:", error);
    }
  };

  useEffect(() => {
    fetchServiceData();
  }, [serviceId]);

  const userdata = localStorage.getItem("userInfo");
  let userId: any;
  let uname: any;
  if (userdata) {
    const userInfo = JSON.parse(userdata);
    userId = userInfo.id;
    uname = userInfo.first_name;
  }

  function stripHtml(html: string) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const plainTextDesc = stripHtml(service?.service_desc);
  const shareText = `Checkout New Service:\n Service Name: ${
    service?.service_name
  }\n ${plainTextDesc.slice(0, 100)}\n Link:\n`;

  const generateImagesForGallery = () => {
    const serviceImages: any = [];
    service?.product_images?.forEach((imageUrl: string) => {
      serviceImages.push({
        original: imageUrl,
        thumbnail: imageUrl,
        originalHeight: 1000, // Adjust the height as needed
        originalWidth: 800,
      });
    });
    setGalleryImages(serviceImages);
  };

  useEffect(() => {
    generateImagesForGallery();
  }, [service]);

  useEffect(() => {
    generateImagesForGallery();
  }, [service]);

  const id = serviceData.id;

  const handleProductClick = async () => {
    if (isBuyNowDisabled === false) {
      console.log(
        "data for selected color ",
        form.getFieldValue("selectedColor")
      );
      const mainData = _.get(service, "inventory", []);
      const selectedData = _.filter(
        mainData,
        (item) => item.color === itemColor
      );
      console.log("selectedData", selectedData);
      await dispatch(
        SetProductOrderingProduct({
          inventory_id: _.get(selectedData[0], "inventory_id", null),
          color: itemColor,
          quantity: count,
          size: size,
        })
      );
      console.log("ID IS ID IS", selectedData, itemColor);

      // SetProductOrderingProduct;
      history.push(
        `${APPLICATION_ROUTES.PRODUCT_BOOKING}/${service.assigned_product_id}`,
        {
          service: service,
          quantity: count,
          color: itemColor,
          size: size,
          productData: productData,
        }
      );
      return;
    } else {
      notification.error({
        message: "Size or Color not Selected",
        description: "Please select Size/Colour before booking.",
        placement: "topRight",
      });
    }
  };

  const handleAddToCart = async () => {
    console.log("data inside product : ", productData);

    if (isAddCartDisabled === false) {
      const inventory = serviceData.inventory.filter(
        (item: any) => item.size === size && item.color === itemColor
      );
      console.log(
        "inventory is : ",
        inventory,
        serviceData.inventory,
        itemColor,
        size
      );

      const payload = {
        itemDetails: {
          productItemId: serviceData.assigned_product_id,
          itemId: `${serviceData.assigned_product_id}-${inventory[0].inventory_id}`,
          itemCategory: serviceData.product_category,
          itemPrice:
            serviceData.discountPercentage === 0
              ? serviceData.product_unit_price
              : serviceData.product_discount_amount,
          itemName: serviceData.product_name,
          itemPic: serviceData.product_images,
          quantity: count,
          itemType: "product",
          supplierId: productData?.supplier_info.supplier_id,
          inventory: inventory,
          freelancerId: serviceData.freelancer_info.id,
        },
      };
      try {
        const response = await _POST(`cart/addItem/${userId}`, payload, true);
        if (response && response.status === false) {
          notification.open({
            type: "error",
            message: `${response.message}`,
            description: _.get(response, "response.message", ""),
          });
        } else {
          setCartItems((prevItems: any) => [...prevItems, payload.itemDetails]);
          notification.success({
            message: "Added to Cart Successful",
            description: "You have successfully Added to Cart.",
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      notification.error({
        message: "Size or Color not Selected",
        description: "Please select Size/Colour before adding to cart.",
        placement: "topRight",
      });
    }
  };

  const fetchCartItem = async () => {
    if (userRole !== "guest") {
      try {
        const res = await _GET(`/cart/getItem/${userId}`, true);

        if (res.status) {
          const itemsArray: any[] = Object.values(res.data)
            .map((item: any) => JSON.parse(item))
            .flat();

          const serviceItems: any = itemsArray.filter(
            (item) => item.itemType === "service"
          );
          setCartItems(serviceItems);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchCartItem();
  }, []);

  useEffect(() => {
    console.log("DUUMY DATA VALUES ARE");
    console.log("Values", form.getFieldsValue());
  }, [form]);

  const isItemInCart = (serviceId: any) => {
    return cartItems.some((item: { itemId: any }) => item.itemId === serviceId);
  };

  const [sameProvider, setSameProvider] = useState<any>(false);

  const checkProvider = () => {
    serviceData.provider_id === userId
      ? setSameProvider(true)
      : setSameProvider(false);
  };

  useEffect(() => {
    checkProvider();
  }, [serviceData]);

  const handleSavedItems = async () => {
    const payload = {
      itemType: "product",
      itemId: service.product_id,
    };

    try {
      const postData = await _POST("userProfile/saveItem", payload, true);
      setSavedItem(true);
    } catch (error) {
      console.error(error);
    }
  };
  const toggleDisplayAll = () => {
    setDisplayAllCards(!displayAllCards);
  };
  const visibleReviewsData = displayAllCards
    ? reviewsData
    : reviewsData.slice(0, 1);

  return (
    <>
      <LoginPopup open={isLoginPopupOpen} onClose={handleCloseLoginPopup} />
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />

        <div className={Styles.serviceMainContainer}>
          <Row gutter={36}>
            <Col lg={12}>
              <div style={{ height: "900px", position: "relative" }}>
                {/* <ImageGallery
                  items={galleryImages}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  renderLeftNav={(onClick, disabled) => (
                    <Button
                      onClick={onClick}
                      disabled={disabled}
                      className="elevatedElement iconBtn"
                      style={{
                        position: "absolute",
                        top: "90%",
                        left: "4%",
                        width: "40px",
                        height: "40px",
                        padding: 0,
                        background: "rgba(255,255,255, 0.5)",
                      }}
                    >
                      <LeftCircle />
                    </Button>
                  )}
                  renderRightNav={(onClick, disabled) => (
                    <Button
                      onClick={onClick}
                      disabled={disabled}
                      className="elevatedElement iconBtn"
                      style={{
                        position: "absolute",
                        top: "90%",
                        right: "4%",
                        width: "40px",
                        height: "40px",
                        padding: 0,
                        background: "rgba(255,255,255, 0.5)",
                      }}
                    >
                      <RightCircle />
                    </Button>
                  )}
                /> */}
                <GlobalCarousel images={galleryImages} />
              </div>
            </Col>
            <Col xs={18} md={20} lg={10}>
              <div className={Styles.serviceInfo}>
                {/* <DayPicker /> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  {service?.product_unit_price && (
                    <>
                      <p style={{ fontSize: "18px", color: "#666" }}>BHD </p>
                      <div className={Styles.productPriceStyle}>
                        {service?.discountPercentage === 0 ||
                        service.discounted_amount === 0 ? (
                          <div className={Styles.productPriceDiv}>
                            {service?.product_unit_price}
                          </div>
                        ) : (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className={Styles.productPriceDiv}>
                              {service?.product_discount_amount}
                            </div>
                            <span style={{ textDecoration: "line-through" }}>
                              /
                              {typeof service?.product_unit_price === "number"
                                ? service?.product_unit_price.toFixed(2)
                                : service?.product_unit_price}
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {service?.product_category && (
                    <Button
                      className="elevatedElement"
                      style={{ borderRadius: "25px" }}
                    >
                      <b>{service?.product_category}</b>
                    </Button>
                  )}
                </div>
                {service?.product_name && (
                  <h2>{getCapitalize(service?.product_name)}</h2>
                )}
                {service?.product_description && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: showFullDescription
                          ? service?.product_description
                          : `${service?.product_description.slice(0, 100)}...`,
                      }}
                      className={`richDesc ${Styles.serviceDescription}`}
                      style={{
                        width: "100%",
                      }}
                    ></div>
                    {!showFullDescription && (
                      <p
                        className="readMore"
                        onClick={() => setShowFullDescription(true)}
                      >
                        Continue Reading
                      </p>
                    )}
                  </div>
                )}

                <Drawer
                  title="Full Description"
                  placement="right"
                  closable={true}
                  onClose={() => setShowFullDescription(false)}
                  open={showFullDescription}
                  width={400}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: service?.product_description,
                    }}
                    className={Styles.serviceDescription}
                  ></div>
                </Drawer>
                <div className={Styles.varientConatiner}>
                  <Form
                    form={form}
                    initialValues={{
                      selectedColor: "default",
                      selectedSize: "default",
                    }}
                  >
                    <p className={Styles.label}>
                      <b style={{ color: "gray" }}>Color</b>
                    </p>
                    <Form.Item
                      name="selectedColor"
                      label=""
                      rules={[
                        { required: true, message: "Please select a color" },
                      ]}
                    >
                      <div className={Styles.selectInput}>
                        <Select
                          placeholder="Select a color"
                          className={Styles.select}
                          onChange={(value: any) => {
                            console.log("value", value);
                            setSelectedColor(true);
                            setItemColor(value);
                            const mainData = _.get(service, "inventory", []);
                            const selectedData = _.filter(
                              mainData,
                              (item) => item.color === value
                            );
                            if (selectedData && selectedData.length > 0) {
                              let serviceImages: any = [];
                              let uhu = selectedData[0].inventory_images;
                              uhu.forEach((imageUrl: string) => {
                                serviceImages.push({
                                  original: imageUrl,
                                  thumbnail: imageUrl,
                                });
                              });
                              console.log("serviceImages", serviceImages);
                              setGalleryImages(serviceImages);
                            } else {
                              const mainData = _.get(
                                service,
                                "product_images",
                                []
                              );
                              let serviceImages: any = [];
                              mainData.forEach((imageUrl: string) => {
                                serviceImages.push({
                                  original: imageUrl,
                                  thumbnail: imageUrl,
                                });
                              });
                              console.log("serviceImages", serviceImages);
                              setGalleryImages(serviceImages);
                            }
                            // form.setFieldsValue({ selectedColor: value });

                            setSelectedTags(null);
                            form.setFieldValue("selectedColor", value);
                            form.setFieldValue("selectedSize", "default");
                          }}
                        >
                          {/* <Option
                            key={"default"}
                            value={"default"}
                            className={Styles.option}
                            onClick={(item: any) => {
                              console.log("object", item);
                            }}
                          >
                           
                          </Option> */}
                          {_.map(service.inventory, (color: any) => {
                            return (
                              <Option
                                key={color.color}
                                value={color.color}
                                className={Styles.option}
                                onClick={(item: any) => {
                                  console.log("object", item);
                                }}
                              >
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: color.colorCode,
                                    marginRight: "10px",
                                    borderRadius: "50%",
                                  }}
                                />
                                {color.color}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </Form.Item>
                    <p className={Styles.label}>
                      <b style={{ color: "gray" }}>Size</b>
                    </p>
                    <Form.Item
                      name="selectedSize"
                      label=""
                      rules={[
                        { required: true, message: "Please select a size" },
                      ]}
                    >
                      <div>
                        {_.map(service.inventory, (size: any, index: any) => {
                          return (
                            <Tag.CheckableTag
                              style={{
                                width: "fit-content",
                                height: "30px",
                                textAlign: "center",
                                border: "1px solid #ccc", // Light gray border
                                borderRadius: "15px",
                                alignContent: "center",
                                background:
                                  selectedTags === index ? "#31263e" : "#fff",
                                pointerEvents:
                                  size.color === itemColor ? "auto" : "none",
                                opacity: size.color === itemColor ? 1 : 0.5,
                              }}
                              key={index}
                              checked={selectedTags === index ? true : false}
                              onChange={(checked) => {
                                handleChange(index);
                                setSize(size.size);
                                setSelectedSize(true);
                              }}
                            >
                              <div
                                style={{
                                  display: "inline-block",
                                  // width: "20px",
                                  // height: "20px",
                                  minWidth: "20px",

                                  borderRadius: "50%",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                {size.size}
                              </div>
                            </Tag.CheckableTag>
                          );
                        })}
                      </div>
                    </Form.Item>
                  </Form>
                  <p className={`${Styles.label} ${Styles.labelStyle}`}>
                    <b style={{ color: "gray" }}>Style</b>
                  </p>
                  <p className={`${Styles.label} ${Styles.styleContent}`}>
                    {serviceData.product_style}
                  </p>
                </div>
                <div className={Styles.buttonContainer}>
                  <div className={Styles.cartCount}>
                    <button
                      onClick={
                        userRole !== "guest"
                          ? decrementCount
                          : handleOpenLoginPopup
                      }
                      className={Styles.CartButton}
                    >
                      <img src={MinusIcon} alt="Decrease" />
                    </button>
                    <span className={Styles.countDisplay}>
                      {String(count).padStart(2, "0")}
                    </span>
                    <button
                      onClick={
                        userRole !== "guest"
                          ? incrementCount
                          : handleOpenLoginPopup
                      }
                      className={Styles.CartButton2}
                    >
                      <img src={PlusIcon} alt="Increase" />
                    </button>
                  </div>
                  {isItemInCart(serviceData.id) ? (
                    <Button
                      type="primary"
                      className={Styles.addedToCart}
                      disabled
                    >
                      Already Added to Cart
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className={Styles.customButton}
                      onClick={
                        userRole !== "guest"
                          ? handleAddToCart
                          : handleOpenLoginPopup
                      }
                    >
                      Add to Cart
                    </Button>
                  )}

                  <Button
                    onClick={
                      userRole !== "guest"
                        ? handleProductClick
                        : handleOpenLoginPopup
                    }
                    // disabled={isBuyNowDisabled}
                  >
                    Buy Now
                  </Button>
                </div>

                <div className={Styles.boxInfoContainer}>
                  <div className={Styles.collabration}>
                    {/* <div className={Styles.profileImage}>
                   
                    </div> */}
                    <p>
                      Collobrated with{" "}
                      <span className={Styles.collabBrand}>
                        {_.get(
                          service,
                          "freelancer_info.first_name",
                          "Freelancer"
                        )}{" "}
                        {_.get(service, "freelancer_info.last_name", "")}
                      </span>{" "}
                    </p>
                  </div>
                  <ServiceProviderCard
                    serviceProviderDetails={service?.freelancer_info}
                  />
                </div>
              </div>

              {/* <div className={Styles.othersOfProvider}>
                <h2>{`Provider's Store`}</h2>
                <div className={Styles.otherServices}>
                  {serviceData.map((service: any) => (
                    <ServiceCard
                      key={service?.product_id}
                      service={service}
                      setUpdateList={setUpdateList}
                      similarBy={true}
                      categoriesData={categoriesData}
                    />
                  ))}
                </div>
              </div> */}
              <Divider />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  justifyItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    height: "100%",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>{reviewsData.length} Reviews</h2>
                </div>
                <div
                  onClick={toggleDisplayAll}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    height: "100%",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "end",
                    textDecoration: "underline",
                  }}
                >
                  See All
                </div>
              </div>
              <ReviewCard reviews={visibleReviewsData} />
            </Col>
            <Col span={2}>
              <div className={Styles.serviceActions}>
                {/* {isProvider && sameProvider && (
                  <button
                    onClick={confirmDelete}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Delete />
                  </button>
                )}
                {isProvider && sameProvider && (
                  <button
                    onClick={openToAdd}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Edit />
                  </button>
                )} */}

                {!savedItem ? (
                  <button
                    onClick={(event) => {
                      handleSavedItems();
                      event.preventDefault();
                    }}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Save />
                  </button>
                ) : (
                  <img
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                    src={savedItemImage}
                  ></img>
                )}
                <div>
                  <RWebShare
                    data={{
                      text: shareText,
                      url: currentPageUrl,
                      title: service?.service_name,
                    }}
                  >
                    <button
                      className="elevatedElement iconBtn"
                      style={{
                        position: "relative",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                    >
                      <Share />
                    </button>
                  </RWebShare>
                </div>
                <LoginPopUp
                  open={isLoginPopupOpen}
                  onClose={handleCloseLoginPopup}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
