import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Layout, Popconfirm, Tabs, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { _GET, _POST } from "../../../common/commonFunc";
import Styles from "./Inventory.module.css";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import CustomCard from "./CustomCard";
import CustomInventoryTable from "./CustomInventoryTable";
import TabPane from "antd/es/tabs/TabPane";
import axios from "axios";
import CollaborationDetailsTable from "./CollaborationDetailsTable";
import AddContractDrawer from "./AddContractDrawer";

const CollabDetails = (ids: any) => {
  const { id, freelancer_id } = useParams<{
    id: string;
    freelancer_id: string;
  }>();
  const [inventoryData, setInventoryData] = useState<any>([]);
  const [cardData, setCardData] = useState<any>([]);
  const history = useHistory();
  const [contractDetails, setContractDetails] = useState<any>({});
  const [activeTab, setActiveTab] = useState("product");
  const [collabDetails, setCollabDetails] = useState<any>();
  const [openContractDrawer, setOpenContractDrawer] = useState(false);

  const getCollaborationDetails = async () => {
    try {
      const res = await _GET(`supplier/allVerifiedCollaborations`, true);
      console.log("response is : ", res.data);
      if (res.data && res.data.length > 0) {
        const filteredData = res.data.find(
          (item: any) => item.collaborationRequestId === id
        );
        if (filteredData) {
          setContractDetails(filteredData);
          setCardData(filteredData);
        } else {
          setContractDetails({});
        }
      } else {
        setContractDetails({});
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      return {};
    }
  };

  useEffect(() => {
    getCollaborationDetails();
    getCollabDetailsNonProduct();
    getInventoryData();
  }, []);

  const getCollabDetailsNonProduct = async () => {
    try {
      const res = await axios.get(
        ` https://development.theoctopus.ai:8443/supplier/getNonProductContracts?request_id=${id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );
      const resposne = res.data.data;

      if (res.data && resposne) {
        console.log("Response from collabDetails :", resposne);
        if (resposne?.contracts?.length > 0) {
          setCollabDetails(resposne);
        } else {
          setCollabDetails([]);
        }
      } else {
        console.error("Error fetching Collaboration Details");
        setCollabDetails([]);
      }
    } catch (error) {
      console.error("Error fetching Collaboration Details: ", error);
    }
  };

  const handleTabChange = (status: any) => {
    setActiveTab(status);
  };

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const extendCollab = async (id: any) => {
    console.log("🚀 ~ extenddCollab ~ id:", id);
    const payload = {};
    try {
      const response = await _POST(
        `supplier/extend-collaboration-request/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Collaboration extended successfully");
      } else {
        message.error(
          response.data && response.statusCode
            ? response.message
            : "Extend Collaboration failed"
        );
      }
    } catch (error) {
      console.log("🚀 ~ extendCollab ~ error:", error);
    }
  };

  const confirmExtendCollab = (request_id: any) => {
    extendCollab(request_id);
  };

  const getInventoryData = async () => {
    try {
      const res = await axios.get(
        ` https://development.theoctopus.ai:8443/supplier/inventoryDetails?requestId=${id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );

      if (res.data.data) {
        setInventoryData(res.data.data);
      } else {
        setInventoryData([]);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      return {};
    }
  };

  const handleAddContract = () => {
    setOpenContractDrawer(true);
  };

  const handleAddContractClose = () => {
    setOpenContractDrawer(false);
  };

  const refreshData = () => {
    getInventoryData();
    getCollaborationDetails();
    getCollabDetailsNonProduct();
  };

  // useEffect(() => {
  //   if (contractDetails?.collaborated_freelancers) {
  //     getInventoryData();
  //   }
  // }, [contractDetails]);

  const headers = [
    { id: "product_name", label: "PRODUCT", width: "10%" },
    { id: "prod_desc", label: "DESCRIPTION", width: "15%" },
    { id: "product_unit_price", label: "Price (BHD)", width: "10%" },
    { id: "affiliate_link", label: "AFFILIATE LINKS", width: "10%" },
    { id: "assigned_quantity", label: "ASSIGNED UNIT", width: "10%" },
    { id: "sold_unit", label: "SOLD UNIT", width: "10%" },
    { id: "unsold_unit", label: "UNSOLD UNIT", width: "10%" },
    { id: "action", label: "ACTION", width: "10%" },
  ];

  const headers_np = [
    { id: "contract_id", label: "CONTRACT ID", width: "15%" },
    { id: "detailed_info", label: "DETAILED INFORMATION", width: "25%" },
    {
      id: "collab_interest",
      label: "COLLABORATION INTEREST & EXPECTATIONS",
      width: "25%",
    },
    { id: "fixed_amount", label: "FIXED AMOUNT", width: "15%" },
    { id: "action", label: "ACTION", width: "20%" },
  ];

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA",
      }}
      className={Styles.customLayoutBG}
    >
      <div className={Styles.header}>
        <h1>
          <span className={Styles.countingSpanParent}>
            <span
              className={Styles.gradientText}
              style={{
                WebkitBackgroundClip: "text",
              }}
            >
              Collaborator
            </span>{" "}
            Details
          </span>
        </h1>
        <div className={Styles.serviceHeader}>
          <div className={Styles.actionsContainerMain}>
            {/* <CustomDropdown
              freelancers={freelancer_info}
              onFreelancerSelect={handleFreelancerSelect}
            /> */}
            <Popconfirm
              title="Are you sure you want to extend this collaboration?"
              onConfirm={() =>
                confirmExtendCollab(contractDetails.collaborationRequestId)
              }
              okText="Yes"
              cancelText="No"
            >
              <Button className={Styles.buttonFilled}>
                Extend Collaboration
              </Button>
            </Popconfirm>
            <Button className={Styles.buttonTransparent}>Send Message</Button>
            {/* <Button onClick={showDrawer} className={Styles.buttonTransparent}>
              Contract Details
            </Button> */}
          </div>
        </div>
      </div>

      <Breadcrumb separator={<img src={breadcrumbIcon} alt=">" />}>
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/collabration")}
        >
          Collaborations
        </Breadcrumb.Item>
        <Breadcrumb.Item>Collaborations detail</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{
          height: "auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        className={Styles.parentContentdiv}
      >
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          {cardData && <CustomCard data={cardData} />}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "40px",
              marginRight: "40px",
            }}
          >
            <div>
              {activeTab === "product" && <h3>Share product information</h3>}
              {activeTab === "non_product" && (
                <h3>Freelancer contract information</h3>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      PRODUCT BASED{" "}
                      <span className={Styles.countingSpan}>
                        {inventoryData?.length}
                      </span>
                    </span>
                  }
                  key="product"
                />
                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      NON PRODUCT BASED{" "}
                      <span className={Styles.countingSpan}>
                        {collabDetails && collabDetails?.contracts
                          ? collabDetails?.contracts.length
                          : 0}
                      </span>
                    </span>
                  }
                  key="non_product"
                />
              </Tabs>
              <Button
                style={{ marginTop: "5px" }}
                className={Styles.buttonFilled}
                onClick={handleAddContract}
              >
                Add Contract
              </Button>
            </div>
          </div>
        </div>
        {activeTab === "product" && (
          <CustomInventoryTable headers={headers} data={inventoryData} />
        )}
        {activeTab === "non_product" && (
          <CollaborationDetailsTable
            headers={headers_np}
            data={collabDetails}
          />
        )}
      </div>

      {openContractDrawer && (
        <AddContractDrawer
          freelancer_id={freelancer_id}
          visible={openContractDrawer}
          request_id={id}
          refreshData={refreshData}
          onClose={handleAddContractClose}
        />
      )}
    </Layout>
  );
};

export default CollabDetails;
