import React, { useEffect, useState } from "react";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import Styles from "./FormStyle.module.css";
import {
  CalendarOutlined,
  GoogleOutlined,
  LockOutlined,
  MailOutlined,
  ManOutlined,
  UserOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  notification,
  theme,
} from "antd";
// HEllo
import moment from "moment";
import { useHistory } from "react-router";
import "./Register.css";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { _POST, getRole } from "../../common/commonFunc";
import countries from "../../pages/SupplierRegister/Data/countries.json";
import _, { first } from "lodash";
import GoogleBtn from "../../common/GoogleBtn/GoogleBtn";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import { AuthContext } from "../../context/authContext";
import { getHashPassword } from "../../common/gethashPassword";
import axios from "axios";
import { BASE_URL } from "../../constant/ApplicationRoutes";
import SkipButtonIcon from "../../assests/Skip01.svg";
import googleLogo from "../../assests/googleLogo.png";
import { Helmet } from "react-helmet-async";
import { noSpecialCharactersValidator } from "../../utils/helpers";

const { Option } = Select;
type Props = {};

const Register = (props: Props) => {
  const authContext = React.useContext(AuthContext);
  const { token } = theme.useToken();
  const [form] = Form.useForm<any>();
  const [isGoogleSignup, setisGoogleSignup] = useState(false);
  const [isEKYCSIGNUP, setIsEKYCSIGNUP] = useState<any>(false);
  const [ekycData, setEkycData] = useState<any>({});
  const [googleData, setGoogleData] = useState<any>({});
  const [initialValues, setinitialValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [emailExists, setEmailExists] = useState(false);
  const userRole = getRole();
  console.log("🚀 ~ Register ~ userRole:", userRole);

  const debounce = (func: any, delay: any) => {
    let debounceTimer: any;
    return function (this: any) {
      const context: any = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const isValidEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = debounce(async (e: any) => {
    const email = e.target.value;
    if (email && isValidEmail(email)) {
      try {
        const response = await axios.post(
          `${BASE_URL}checkUserExists/${email}`
        );
        if (response.data.status) {
          setEmailExists(true);
        } else {
          setEmailExists(false);
        }
      } catch (error) {
        console.error("Error checking email", error);
        setEmailExists(false);
      }
    }
  }, 500);

  useEffect(() => {
    if (emailExists === true) {
      notification.error({
        message: "User Already Exists",
        description:
          "User already exists with this email. Please login or use the forgot password feature.",
      });
    }
  }, [emailExists]);

  const getYearMonthDate = (date1: any) => {
    const date = new Date(date1);

    return moment(date).format("YYYY-MM-DD");
  };

  const history = useHistory();
  const validatePassword = (rule: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error("Password is required"));
    }

    const minLength = 8;
    const hasMinLength = value.length >= minLength;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumbers = /\d/.test(value);
    const specialCharMatch = value.match(/[^A-Za-z0-9]/g);
    const hasTwoSpecialChars = specialCharMatch && specialCharMatch.length >= 2;

    if (!hasMinLength) {
      return Promise.reject(
        new Error(`Password must be at least ${minLength} characters long`)
      );
    }
    if (!hasUpperCase) {
      return Promise.reject(
        new Error("Password must include at least one uppercase letter")
      );
    }
    if (!hasLowerCase) {
      return Promise.reject(
        new Error("Password must include at least one lowercase letter")
      );
    }
    if (!hasNumbers) {
      return Promise.reject(
        new Error("Password must include at least one number")
      );
    }
    if (!hasTwoSpecialChars) {
      return Promise.reject(
        new Error("Password must include at least two special characters")
      );
    }

    return Promise.resolve();
  };

  const [myGoogleSignup, setMyGoogleSignup] = useState(false);
  const [errorExist, setErrorExist] = useState(false);
  // const [googleData, setGoogleData] = useState(null);

  const onContinueWithGoogle = async (values: any) => {
    console.log("Initial myGoogleSignup state: ", myGoogleSignup);

    try {
      const response = await axios.post(
        `${BASE_URL}checkUserExists/${values.email}`
      );
      console.log("🚀 ~ onContinueWithGoogle ~ response:", response);

      if (response.data.status) {
        setEmailExists(true);
        setMyGoogleSignup(false);
        console.log("User exists, setting myGoogleSignup to false");
        setErrorExist(false);
      } else {
        setEmailExists(false);
        setErrorExist(true);
        // Move state updates to useEffect callback
        setGoogleData(values);
      }
    } catch (error: any) {
      console.error("Error checking email", error.response?.status);
      setEmailExists(false);
      setErrorExist(true);
      setGoogleData(values);
    }
  };

  // Use an effect to monitor state changes
  useEffect(() => {
    if (errorExist && googleData) {
      setMyGoogleSignup(true);
      console.log("Email not found, setting myGoogleSignup to true");

      form.setFieldsValue({
        email: googleData.email,
        firstName: googleData.firstName,
        lastName: googleData.lastName,
        password: googleData.token,
      });

      console.log("myGoogleSignup state after setting:", myGoogleSignup);

      // Delay the logging to ensure state is updated
      setTimeout(() => {
        const formValues = form.getFieldsValue();
        console.log("Form Values after Google Sign-in on 404:", formValues);
        console.log(
          "myGoogleSignup state after setting form values on 404:",
          myGoogleSignup
        );

        // Additional check to ensure state is updated
        if (myGoogleSignup) {
          onFormSubmitHandler(formValues);
        } else {
          console.error("myGoogleSignup state not set to true as expected.");
        }
      }, 0);
    }
  }, [errorExist, googleData]);

  const onEKYCHandler = async (values: any) => {
    setIsEKYCSIGNUP(true);

    const response = await _POST(
      "verificationResult/fetch/eKyc",
      {
        ekyc_response_id: values,
      },
      false
    );

    const mainData = _.get(response, "data.data.kycResponse.result", false);
    console.log("🚀 ~ onEKYCHandler ~ mainData:", mainData);

    if (mainData) {
      setEkycData(mainData);
      if (
        !mainData.lastName ||
        !mainData.firstName ||
        !mainData.dob ||
        !mainData.nationality_full ||
        !mainData.sex
      ) {
        notification.open({
          type: "error",
          message: "Failed to fetch details from E_kyc",
          description:
            "Sorry we are unable to fetch details from your EKYC account. Please enter your details manually.",
        });
        setIsEKYCSIGNUP(false);
        return;
      }
      form.setFieldValue("lastName", mainData.lastName);
      form.setFieldValue("firstName", mainData.firstName);
      form.setFieldValue("birthdate", moment(mainData.dob, "YYYY-MM-DD"));
      form.setFieldValue("country", mainData.nationality_full);
      form.setFieldValue("gender", mainData.sex === "M" ? "Male" : "Female");
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("ekyc_response_id")) {
      onEKYCHandler(localStorage.getItem("ekyc_response_id"));
    }
  }, []);

  const onFormSubmitHandler = async (values?: any) => {
    console.log("🚀 ~ onFormSubmitHandler ~ values:", myGoogleSignup, values);
    if (emailExists) {
      if (emailExists === true) {
        notification.error({
          message: "User Already Exists",
          description:
            "User already exists with this email. Please login or use the forgot password feature.",
        });
      }
      setEmailExists(true);
      return;
    }
    let hashedPassword;
    if (myGoogleSignup) {
      hashedPassword = values.password;
    } else {
      hashedPassword = await getHashPassword(values.password);
    }
    const fullPhoneNumber = myGoogleSignup
      ? ""
      : `${values.phoneNumber.countryCode}${values.phoneNumber.number}`;

    const mainValues = {
      ...values,
      phoneNumber: `${fullPhoneNumber}`,
      registerType: myGoogleSignup ? "Google" : "Manually",
      password: hashedPassword,
      firstName: isEKYCSIGNUP
        ? ekycData.firstName
        : myGoogleSignup
        ? googleData.firstName
        : values.firstName,
      lastName: isEKYCSIGNUP
        ? ekycData.lastName
        : myGoogleSignup
        ? googleData.lastName
        : values.lastName,
      birthdate: isEKYCSIGNUP
        ? getYearMonthDate(ekycData.dob)
        : myGoogleSignup
        ? getYearMonthDate(googleData.birthdate)
        : getYearMonthDate(values.birthdate),
      gender: isEKYCSIGNUP
        ? ekycData.sex
        : myGoogleSignup
        ? googleData.gender
        : values.gender,
      role: localStorage.getItem("octo_role"),
      phoneCountryCode: myGoogleSignup
        ? ""
        : `${values.phoneNumber.countryCode}`,
      phone_no: myGoogleSignup ? "" : `${values.phoneNumber.number}`,
      country: isEKYCSIGNUP
        ? ekycData.nationality_full
        : myGoogleSignup
        ? ""
        : values.country,
    };

    console.log("🚀 ~ onFormSubmitHandler ~ mainValues:", mainValues);

    if (myGoogleSignup) {
      delete mainValues.phone_no;
      if (!isEKYCSIGNUP) {
        delete mainValues.country;
      }
      delete mainValues.phoneCountryCode;
      delete mainValues.phoneNumber;
    }

    if (
      values.birthdate === undefined ||
      mainValues.birthdate === "Invalid date"
    ) {
      delete mainValues.birthdate;
    }
    if (values.gender === undefined) {
      delete mainValues.gender;
    }

    if (isEKYCSIGNUP) {
      mainValues.ekyc_response_id = localStorage.getItem("ekyc_response_id");
    }

    const response = await _POST("signup", mainValues, false);

    if (response && !response.status) {
      if (response.data && response.data.user.is_email_verified === false) {
        history.push(APPLICATION_ROUTES.EMAIL_VERIFY);
        return;
      }

      if (response.data && response.data.user.is_phone_verified === false) {
        history.push(APPLICATION_ROUTES.VERIFY_OTP);
        return;
      }
    }
    if (response && response.status) {
      const userInfo = {
        id: response.data.user.id,
        first_name: response.data.user.first_name,
        last_name: response.data.user.last_name,
        email: response.data.user.email,
        phone_number: response.data.user.phone_number,
        birthdate: response.data.user.birthdate,
        gender: response.data.user.gender,
        country: response.data.user.country || values.country,
        register_type: response.data.user.register_type,
        zoho_user_id: response.data.user.zoho_user_id,
        countryCode: response?.data?.user.country_code,
        phoneNumber: response?.data?.user.phone_no,
        phone_no: response?.data?.phone_no || response?.data?.user?.phone_no,
        country_code:
          response?.data?.country_code || response?.data?.user?.country_code,
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      localStorage.setItem("userEmail", values.email);
      localStorage.setItem("zohoUserID", response.data.user.zoho_user_id);
      localStorage.setItem("supplier_id", response.data.user.supplier_id);
      localStorage.setItem("Temp_jwtToken", response.data.token);
      localStorage.setItem("supplier_form_step", "0");
      await authContext?.onRegistration({
        ...values,
        registerType: myGoogleSignup ? "Google" : "Manually",
        birthdate: getYearMonthDate(values.birthdate),
        role: localStorage.getItem("octo_role"),
      });

      if (myGoogleSignup) {
        showSuccessCustomModal({
          title: myGoogleSignup
            ? "Your Registration Successfull"
            : "Verification Mobile OTP sent Successfully",
          smallText: `We got your details successfully. PLease verify your ${
            myGoogleSignup ? "Mobile Number" : "Email"
          }`,
          buttonText: "Continue",
        });
        history.push(APPLICATION_ROUTES.VERIFY_OTP);
      } else {
        showSuccessCustomModal({
          title: "Verification Email sent Successfully",
          smallText: `We got your details successfully. We sent an OTP to your ${
            myGoogleSignup ? "Mobile Number" : "Email"
          } for verification`,
          buttonText: "Continue",
        });
        history.push(APPLICATION_ROUTES.EMAIL_VERIFY);
      }
    }
  };

  const PhoneNumberInput = ({ value = {}, onChange }: any) => {
    const [number, setNumber] = React.useState(value.number || "");
    const [countryCode, setCountryCode] = React.useState(
      value.countryCode || "+973"
    );

    const triggerChange = (changedValue: any) => {
      onChange?.({
        number,
        countryCode,
        ...value,
        ...changedValue,
      });
    };

    const onNumberChange = (e: any) => {
      const newNumber = e.target.value.replace(/\D/g, "");
      setNumber(newNumber);
      triggerChange({ number: newNumber });
    };

    const onCountryCodeChange = (newCountryCode: any) => {
      setCountryCode(newCountryCode);
      triggerChange({ countryCode: newCountryCode });
    };

    return (
      <div className={Styles.NormalInput}>
        <Input.Group compact className={`${Styles.phoneNumberInput}`}>
          <Select
            value={countryCode}
            onChange={onCountryCodeChange}
            className={Styles.selectCountryCode}
            showSearch
            optionFilterProp="children"
          >
            {countries.map((country: any) => (
              <Option key={country.code} value={country.dial_code}>
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                {country.dial_code}
              </Option>
            ))}
          </Select>
          <div className={Styles.dividerInput} />
          <Input
            style={{ width: "calc(100% - 100px)" }}
            value={number}
            onChange={onNumberChange}
            placeholder="Enter number"
            className={Styles.FormInput}
          />
        </Input.Group>
      </div>
    );
  };

  const pageTitle = `Register - ${userRole}`;
  const pageDescription = `Register - ${userRole}`;

  return (
    <CustomLayout
      heading1="SignUp"
      heading2={
        myGoogleSignup
          ? ""
          : "Enter your personal information to signup with us."
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <div className="registerFormMainContainer">
        <button
          onClick={() => {
            localStorage.setItem("octo_role", "guest");
            history.push(APPLICATION_ROUTES.DASHBOARD);
          }}
          className="floating-home-button"
        >
          <img
            src={SkipButtonIcon}
            alt=""
            className="floating-home-button-icon"
          />
        </button>
        <Form
          form={form}
          className="registerContainer"
          name="basic"
          size="large"
          layout="vertical"
          onFinish={(values) =>
            onFormSubmitHandler({ ...values, registerType: "Manually" })
          }
          style={{ margin: 0 }}
          initialValues={{
            country: countries[0].name,
            number: {
              countryCode: "+973",
              number: "",
            },
          }}
        >
          {!myGoogleSignup && !isEKYCSIGNUP && (
            <>
              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                      {
                        max: 25,
                        message: "First name cannot exceed 25 characters",
                      },
                      {
                        validator: noSpecialCharactersValidator(
                          "No special characters allowed in the first name!"
                        ),
                      },
                    ]}
                  >
                    <div className={Styles.NormalInput}>
                      <UserOutlined className={Styles.mailIcon} />
                      <Input
                        name="firstName"
                        placeholder="First Name"
                        className={Styles.FormInput}
                        // maxLength={25}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                      {
                        max: 25,
                        message: "First name cannot exceed 25 characters",
                      },
                      {
                        validator: noSpecialCharactersValidator(
                          "No special characters allowed in the last name!"
                        ),
                      },
                    ]}
                  >
                    <div className={Styles.NormalInput}>
                      <UserOutlined className={Styles.mailIcon} />
                      <Input
                        name="lastName"
                        placeholder="Last Name"
                        className={Styles.FormInput}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              {userRole === "freelancer" && (
                <Form.Item
                  label="Display Name (Optional)"
                  name="display_name"
                  rules={[
                    {
                      max: 25,
                      message: "Display name cannot exceed 25 characters",
                    },
                    {
                      validator: noSpecialCharactersValidator(
                        "No special characters allowed in the Display name!"
                      ),
                    },
                  ]}
                >
                  <div className={Styles.NormalInput}>
                    <UserOutlined className={Styles.mailIcon} />
                    <Input
                      name="display_name"
                      placeholder="Display Name"
                      className={Styles.FormInput}
                      // maxLength={25}
                    />
                  </div>
                </Form.Item>
              )}

              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please input your Email!" },
                    ]}
                  >
                    <div className={Styles.NormalInput}>
                      <MailOutlined className={Styles.mailIcon} />
                      <Input
                        name="email"
                        placeholder="Email"
                        className={Styles.FormInput}
                        type="email"
                        onChange={handleEmailChange}
                      />
                    </div>
                  </Form.Item>
                  {emailExists && (
                    <p
                      style={{ color: "red" }}
                    >{`User already exists with this email. \n Please login or use the forgot password feature.`}</p>
                  )}
                </Col>

                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      { validator: validatePassword },
                    ]}
                    hasFeedback
                  >
                    <div className={Styles.NormalInput}>
                      <LockOutlined className={Styles.mailIcon} />
                      <Input.Password
                        placeholder="Password"
                        className={Styles.FormInput}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[{ required: true }]}
                  >
                    <PhoneNumberInput />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="Birth Date (Optional)"
                    name="birthdate"
                    hasFeedback
                    style={{ width: "100%" }}
                    rules={[
                      {
                        // required: true,
                        message: "Please Enter your Birth Date!",
                      },
                    ]}
                  >
                    <div className={Styles.NormalInput}>
                      <CalendarOutlined className={Styles.mailIcon} />
                      <DatePicker
                        disabled={isEKYCSIGNUP}
                        showToday={false}
                        onChange={(date, dateString) => {
                          const currentDate = new Date();
                          const selectedDate = date
                            ? new Date(date.toISOString())
                            : null;

                          // Block future date selection
                          if (selectedDate && selectedDate > currentDate) {
                            notification.open({
                              type: "error",
                              message: "Invalid Date Selection",
                              description: "You cannot select a future date.",
                            });
                            form.setFieldsValue({ birthdate: null });
                            return;
                          }

                          const prev15year = currentDate.getFullYear() - 15;
                          const selectedYear = selectedDate
                            ? selectedDate.getFullYear()
                            : null;

                          // Fetch the user role
                          const userRole = getRole(); // Assuming getRole() function returns the user's role or null/undefined

                          // Apply age validation for all roles except 'user' role
                          if (
                            userRole !== "user" &&
                            selectedYear &&
                            selectedYear > prev15year
                          ) {
                            notification.open({
                              type: "error",
                              message: "You Selected Wrong Date!",
                              description:
                                "You have to be at least 15 years old.",
                            });
                            form.setFieldsValue({ birthdate: null });
                            return;
                          }

                          // Set the date if everything is valid
                          form.setFieldsValue({ birthdate: dateString });
                        }}
                        disabledDate={(current) => {
                          const currentDate = new Date();
                          const prev15year = currentDate.getFullYear() - 15;

                          // Get the role
                          const userRole = getRole(); // Assuming this function returns the user's role or undefined

                          // Disable future dates for all users
                          if (current && current > moment().endOf("day")) {
                            return true;
                          }

                          // For all users except 'user', disable future dates and enforce the 15-year restriction
                          if (userRole !== "user") {
                            return (
                              (current && current > moment().endOf("day")) ||
                              current.year() > prev15year
                            );
                          }

                          // For 'user' role, only disable future dates
                          return current && current > moment().endOf("day");
                        }}
                        placeholder="Birth Date"
                        className={Styles.FormInput}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="Gender (Optional)"
                    name="gender"
                    hasFeedback
                    rules={[
                      {
                        // required: true,
                        message: "Please enter your gender!",
                      },
                    ]}
                  >
                    <div className={Styles.selectInput}>
                      <Select
                        className={Styles.select}
                        defaultActiveFirstOption={true}
                        placeholder="Select Gender"
                        onChange={(value) =>
                          form.setFieldValue("gender", value)
                        }
                      >
                        <Select.Option
                          key={"Male"}
                          value={"Male"}
                          optionFilterProp="children"
                          default
                          selected
                          className={Styles.option}
                        >
                          <ManOutlined />
                          &nbsp;&nbsp; {"Male"}
                        </Select.Option>
                        <Select.Option
                          key={"Female"}
                          value={"Female"}
                          optionFilterProp="children"
                          className={Styles.option}
                        >
                          <WomanOutlined /> &nbsp;&nbsp;
                          {"Female"}
                        </Select.Option>
                      </Select>
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    name="country"
                    label="Nationality"
                    rules={[{ required: true }]}
                  >
                    <div className={Styles.selectInput}>
                      <Select
                        placeholder="Select your business location"
                        className={Styles.select}
                        defaultValue={countries[0].name}
                        showSearch
                        optionFilterProp="value"
                        filterOption={(input, option: any) =>
                          option!.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          form.setFieldsValue({ country: value })
                        }
                      >
                        {countries.map((country) => (
                          <Option
                            key={country.code}
                            value={country.name}
                            className={Styles.option}
                          >
                            <img
                              src={country.flag}
                              alt={country.name}
                              style={{ width: "20px", marginRight: "10px" }}
                              className={Styles.flagIcon}
                            />
                            {country.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <p></p>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {isEKYCSIGNUP && (
            <>
              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                      {
                        max: 25,
                        message: "First name cannot exceed 25 characters",
                      },
                    ]}
                  >
                    <div className={Styles.NormalInput}>
                      <UserOutlined className={Styles.mailIcon} />
                      <Input
                        name="firstName"
                        placeholder="First Name"
                        className={Styles.FormInput}
                        // maxLength={25}
                        value={ekycData.firstName}
                        disabled
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                      {
                        max: 25,
                        message: "First name cannot exceed 25 characters",
                      },
                    ]}
                  >
                    <div className={Styles.NormalInput}>
                      <UserOutlined className={Styles.mailIcon} />
                      <Input
                        name="lastName"
                        placeholder="Last Name"
                        className={Styles.FormInput}
                        value={ekycData.lastName}
                        disabled
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              {userRole === "freelancer" && (
                <Form.Item
                  label="Display Name (Optional)"
                  name="display_name"
                  rules={[
                    {
                      max: 25,
                      message: "Display name cannot exceed 25 characters",
                    },
                  ]}
                >
                  <div className={Styles.NormalInput}>
                    <UserOutlined className={Styles.mailIcon} />
                    <Input
                      name="display_name"
                      placeholder="Display Name"
                      className={Styles.FormInput}
                      // maxLength={25}
                    />
                  </div>
                </Form.Item>
              )}

              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  {myGoogleSignup ? (
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: "Please input your Email!" },
                      ]}
                    >
                      <div className={Styles.NormalInput}>
                        <MailOutlined className={Styles.mailIcon} />
                        <Input
                          name="email"
                          placeholder="Email"
                          className={Styles.FormInput}
                          type="email"
                          onChange={handleEmailChange}
                          value={googleData.email}
                          disabled
                        />
                      </div>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: "Please input your Email!" },
                      ]}
                    >
                      <div className={Styles.NormalInput}>
                        <MailOutlined className={Styles.mailIcon} />
                        <Input
                          name="email"
                          placeholder="Email"
                          className={Styles.FormInput}
                          type="email"
                          onChange={handleEmailChange}
                        />
                      </div>
                      {emailExists && (
                        <p
                          style={{ color: "red" }}
                        >{`User already exists with this email. \n Please login or use the forgot password feature.`}</p>
                      )}
                    </Form.Item>
                  )}
                </Col>

                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      { validator: validatePassword },
                    ]}
                    hasFeedback
                  >
                    <div className={Styles.NormalInput}>
                      <LockOutlined className={Styles.mailIcon} />
                      <Input.Password
                        placeholder="Password"
                        className={Styles.FormInput}
                        disabled={myGoogleSignup}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[{ required: myGoogleSignup ? false : true }]}
                  >
                    <PhoneNumberInput />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="Birth Date (Optional)"
                    name="birthdate"
                    hasFeedback
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: false,
                        message: "Please Enter your Birth Date!",
                      },
                    ]}
                  >
                    <div className={Styles.NormalInput}>
                      <CalendarOutlined className={Styles.mailIcon} />
                      <Input
                        className={Styles.FormInput}
                        value={getYearMonthDate(ekycData.dob)}
                        disabled
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="Gender (Optional)"
                    name="gender"
                    hasFeedback
                    rules={[
                      {
                        // required: true,
                        message: "Please enter your gender!",
                      },
                    ]}
                  >
                    <div className={Styles.selectInput}>
                      <Select
                        className={Styles.select}
                        defaultActiveFirstOption={true}
                        placeholder="Select Gender"
                        onChange={(value) =>
                          form.setFieldValue("gender", value)
                        }
                        value={
                          isEKYCSIGNUP
                            ? ekycData.sex === "M"
                              ? "Male"
                              : "Female"
                            : form.getFieldValue("gender")
                        }
                        disabled={isEKYCSIGNUP}
                      >
                        <Select.Option
                          key={"Male"}
                          value={"Male"}
                          optionFilterProp="children"
                          className={Styles.option}
                        >
                          <ManOutlined />
                          &nbsp;&nbsp; {"Male"}
                        </Select.Option>
                        <Select.Option
                          key={"Female"}
                          value={"Female"}
                          optionFilterProp="children"
                          className={Styles.option}
                        >
                          <WomanOutlined /> &nbsp;&nbsp;
                          {"Female"}
                        </Select.Option>
                      </Select>
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    name="country"
                    label="Nationality"
                    rules={[{ required: true }]}
                  >
                    <div className={Styles.selectInput}>
                      <Select
                        placeholder="Select your business location"
                        className={Styles.select}
                        defaultValue={ekycData.nationality_iso2}
                        showSearch
                        optionFilterProp="value"
                        filterOption={(input, option: any) =>
                          option!.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          form.setFieldsValue({ country: value })
                        }
                        value={
                          isEKYCSIGNUP
                            ? ekycData.nationality_full
                            : form.getFieldValue("country")
                        }
                        disabled
                      >
                        {countries.map((country) => (
                          <Option
                            key={country.code}
                            value={country.name}
                            className={Styles.option}
                          >
                            <img
                              src={country.flag}
                              alt={country.name}
                              style={{ width: "20px", marginRight: "10px" }}
                              className={Styles.flagIcon}
                            />
                            {country.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <p></p>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {myGoogleSignup && !isEKYCSIGNUP && (
            <>
              {/* <div className={Styles.header}>
                <h1 className={Styles.headerTitle}>
                  Welcome {googleData?.firstName}!
                </h1>
                <p className={Styles.headerPara}>
                  You have signed up with Google!
                </p>
                <p className={Styles.headerPara}>
                  Please enter your details to continue!
                </p>
              </div> */}
              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[{ required: true }]}
                    hidden
                  >
                    <PhoneNumberInput />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    name="country"
                    label="Nationality"
                    rules={[{ required: true }]}
                    hidden
                  >
                    <div className={Styles.selectInput}>
                      <Select
                        placeholder="Select your business location"
                        className={Styles.select}
                        defaultValue={countries[0].name}
                        showSearch
                        optionFilterProp="value"
                        filterOption={(input, option: any) =>
                          option!.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          form.setFieldsValue({ country: value })
                        }
                      >
                        {countries.map((country) => (
                          <Option
                            key={country.code}
                            value={country.name}
                            className={Styles.option}
                          >
                            <img
                              src={country.flag}
                              alt={country.name}
                              style={{ width: "20px", marginRight: "10px" }}
                              className={Styles.flagIcon}
                            />
                            {country.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <p></p>
                  </Form.Item>
                </Col>
                <Row>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                      {
                        max: 25,
                        message: "First name cannot exceed 25 characters",
                      },
                    ]}
                    hidden
                  >
                    <div className={Styles.NormalInput}>
                      <UserOutlined className={Styles.mailIcon} />
                      <Input
                        name="firstName"
                        placeholder="First Name"
                        className={Styles.FormInput}
                        // maxLength={25}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                      {
                        max: 25,
                        message: "First name cannot exceed 25 characters",
                      },
                    ]}
                    hidden
                  >
                    <div className={Styles.NormalInput}>
                      <UserOutlined className={Styles.mailIcon} />
                      <Input
                        name="lastName"
                        placeholder="Last Name"
                        className={Styles.FormInput}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please input your Email!" },
                    ]}
                    hidden
                  >
                    <div className={Styles.NormalInput}>
                      <MailOutlined className={Styles.mailIcon} />
                      <Input
                        name="email"
                        placeholder="Email"
                        className={Styles.FormInput}
                        type="email"
                        onChange={handleEmailChange}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      { validator: validatePassword },
                    ]}
                    hasFeedback
                    hidden
                  >
                    <div className={Styles.NormalInput}>
                      <LockOutlined className={Styles.mailIcon} />
                      <Input.Password
                        placeholder="Password"
                        className={Styles.FormInput}
                      />
                    </div>
                  </Form.Item>
                </Row>
              </Row>
            </>
          )}

          <div className="loginButtons" style={{ marginTop: "20px" }}>
            <Form.Item
              style={{
                width: "100%",
                minWidth: "180px",
                marginBottom: 0,
              }}
            >
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                className="buttonBG"
                style={{ width: "100%" }}
                icon={
                  myGoogleSignup ? (
                    <div className={Styles.googleIcon}>
                      <img height="20px" width="20px" src={googleLogo} />
                    </div>
                  ) : (
                    <></>
                  )
                }
              >
                {myGoogleSignup ? "Continue with Google" : "Register"}
              </Button>
            </Form.Item>
            {!myGoogleSignup && (
              <div style={{ width: "100%" }}>
                <GoogleBtn pageType="register" onClick={onContinueWithGoogle} />
              </div>
            )}
          </div>
          <div className="extraButtonSectionLogin">
            <div className="extrasCenter">
              <span>Already have an account ?</span>
              <Button
                style={{ color: "black", fontWeight: "bold", padding: 0 }}
                type="link"
                onClick={() => history.push(APPLICATION_ROUTES.LOGIN)}
              >
                Login ?
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </CustomLayout>
  );
};

export default Register;
