import React, { useEffect, useRef, useState } from "react";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import { Button, Form, Input, notification } from "antd";
import { useHistory } from "react-router";
import { _POST, getRole } from "../../common/commonFunc";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import Styles from "./EmailVerify.module.css";

const EmailVerify = (props: any) => {
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const inputRef1 = useRef<any>(null);
  const inputRef2 = useRef<any>(null);
  const inputRef3 = useRef<any>(null);
  const inputRef4 = useRef<any>(null);
  const inputRef5 = useRef<any>(null);
  const inputRef6 = useRef<any>(null);

  const [counter, setCounter] = useState(60);
  const history = useHistory();
  const userEmail = localStorage.getItem("userEmail");

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const inputChangeHandler = (value: string, index: number) => {
    const setOtpFns = [setOtp1, setOtp2, setOtp3, setOtp4, setOtp5, setOtp6];
    setOtpFns[index](value);

    if (/[^0-9]/.test(value) || !value) return;

    const inputRefs = [
      inputRef1,
      inputRef2,
      inputRef3,
      inputRef4,
      inputRef5,
      inputRef6,
    ];
    if (index < 5) inputRefs[index + 1].current?.focus();
  };

  const handleBackspaceNavigation = (event: any, index: number) => {
    const inputRefs = [
      inputRef1,
      inputRef2,
      inputRef3,
      inputRef4,
      inputRef5,
      inputRef6,
    ];
    if (event.key === "Backspace" && !event.target.value && index > 0) {
      inputRefs[index - 1].current?.focus();
    }
  };

  const verifyEmailHandler = async () => {
    if (!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6) {
      return notification.error({
        message: "Error",
        description: "Please enter all the OTP digits.",
      });
    }

    const otpValue = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;

    if (userEmail) {
      const response = await _POST(
        "verifyEmail",
        { email: userEmail, otp: otpValue },
        false
      );
      if (response.status) {
        showSuccessCustomModal({
          title: "Email Verified Successfully",
          smallText: "Your Email Verification is successfully completed.",
          buttonText: "Continue",
        });
        history.push(APPLICATION_ROUTES.VERIFY_OTP);
        return;
        const userRole = getRole();
        if (userRole === "supplier") {
          showSuccessCustomModal({
            title: "Email Verification Successfully",
            smallText: "Your Email Verification is successfully completed",
            buttonText: "Continue",
          });
          history.push("/supplier-registration");
        } else {
          history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);

          showSuccessCustomModal({
            title: "Email Verification Successfully",
            smallText: "Your Email Verification is successfully completed",
            buttonText: "Continue",
          });
        }
      } else {
        notification.error({
          message: "Error",
          description: `${response.message}`,
        });
      }
    } else {
      notification.error({
        message: "Error",
        description: "Email not found. Please go back to the sign-up page.",
      });
    }
  };

  const resendEmailOTPHandler = async () => {
    if (userEmail) {
      const response = await _POST("resendOTP/" + userEmail, {}, false);
      if (response.status) {
        notification.success({
          message: "Success",
          description: "OTP sent to your email for verification.",
        });
        setCounter(60);
      }
    } else {
      notification.error({
        message: "Error",
        description: "Email not found. Please go back to the sign-up page.",
      });
    }
  };

  return (
    <CustomLayout
      heading1="Verify Your Email"
      heading2="Please enter the OTP sent to your registered email to verify your identity."
    >
      <div className={Styles.verifyOtpContainer}>
        <Form
          size="large"
          className={Styles.verifyOtpContainer}
          onFinish={verifyEmailHandler}
        >
          <div className={Styles.otpInputGroup}>
            {[otp1, otp2, otp3, otp4, otp5, otp6].map((otp, index) => (
              <div key={index} className={Styles.otpInputWrapper}>
                <Input
                  type="text"
                  maxLength={1}
                  ref={
                    [
                      inputRef1,
                      inputRef2,
                      inputRef3,
                      inputRef4,
                      inputRef5,
                      inputRef6,
                    ][index]
                  }
                  className={Styles.otpInput}
                  value={otp}
                  onChange={(e) => inputChangeHandler(e.target.value, index)}
                  onKeyDown={(e) => handleBackspaceNavigation(e, index)}
                />
              </div>
            ))}
          </div>

          <Button
            shape="round"
            type="primary"
            className={Styles.buttonBG}
            htmlType="submit"
          >
            Verify OTP
          </Button>

          {counter > 0 ? (
            <span className={Styles.remainingTimeEmailVerify}>
              Resend OTP in 00:{counter < 10 ? `0${counter}` : counter}
            </span>
          ) : (
            <Button shape="round" type="link" onClick={resendEmailOTPHandler}>
              Resend OTP
            </Button>
          )}
        </Form>
      </div>
    </CustomLayout>
  );
};

export default EmailVerify;
